import React from 'react';
import {Button, Row, Image, Col} from 'react-bootstrap'
import { connect } from 'react-redux';


const IntroductionFooter= (props,{selectedAnswer}) => {
return (

    //add position fixed?
      <Row style={{ margin:'auto',width:'100vw',backgroundColor:'white', bottom: '0'}}>

    <Col style={{paddingBottom: '40px'}}>
      <Button onClick={props.next} style={{width:'16vw',marginTop:'4vh',marginLeft:'2vw',borderColor:'#e5e5e5',borderRadius:'30px',backgroundColor:'white', color:'#afafaf'}} >
    Next
      </Button>
    </Col>
    <Col >

    </Col>

    </Row>
		);
}
const mapStateToProps = state => {
	return {
		selectedAnswer: state.selectedAnswer,
	};
};


// const mapDispatchToProps=(dispatch)=>{
// 	return {checkButtonHasBeenClicked:()=>dispatch(checkButtonHasBeenClicked()), checkButtonHasNotBeenClicked:()=>dispatch(checkButtonHasNotBeenClicked())}
//
//
// }
// export default connect(mapStateToProps,
// mapDispatchToProps)(React.memo(NeutralFooter));
export default connect(mapStateToProps)(React.memo(IntroductionFooter));
