
export const incrementScore=()=>async(dispatch)=>{
    dispatch({
        type:"Increment",
    })
}

export const decrementScore=()=>async(dispatch)=>{
    dispatch({
        type:"Decrement"
    })
}
export const unchangedScore=()=>async(dispatch)=>{
    dispatch({
        type:"Unchanged"
    })
}
export const resetScore=()=>async(dispatch)=>{
    dispatch({
        type:"Reset"
    })
}
