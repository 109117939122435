import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

import styles from './Header.module.css';

const NavButton = props => {
	const active = props.isActive ? ` ${styles.ActiveButton}` : '';
	return (
		<Link to={props.to} className={styles.NavButton + active}>
			{props.children}
		</Link>
	);
};

const Header = props => {
	const url = props.url.split('/')[1];

	let buttons = (
		<React.Fragment>
			{// {<div className={styles.NavRow}>
			// 	<NavButton to="/languages" isActive={url === 'courses' || url === 'course'}>
			// 	Begin a New Language
			// 	</NavButton>
			// 	{/* <NavButton to='/groups' isActive={url === 'groups'}>Groups</NavButton> */}
			// </div>
			// <NavButton className={styles.LogoutBtn}>
			// 	<a onClick={() => {window.open("https://docs.google.com/forms/d/e/1FAIpQLSelbhcYya5lcr6ufVypdNLVfpBTwU-4LjRBhkfHFmHkVp6dYQ/viewform", "_blank")}}>
			// 	Contribute
			// 	</a>
			//
			// </NavButton>}
		}
			<Nav.Link style={{color: "white"}} href="/settings" className={styles.LogoutBtn}>
			<span>Account Settings</span>
			</Nav.Link>
			<Nav.Link style={{color: "white"}} className={styles.LogoutBtn}>
	
			 </Nav.Link>
			
			 
			<Nav.Link style={{color: "white"}} href="/logout" className={styles.LogoutBtn}>
			<span>Log out</span>
			</Nav.Link>

		</React.Fragment>
	);



	return (
		<Navbar collapseOnSelect expand='sm' className={styles.Header} style={{backgroundColor: props.color}}>
			<Container fluid="sm">
			  <Navbar.Brand className={styles.LogoWrapper} href="/">

				</Navbar.Brand>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse id='responsive-navbar-nav'>
					<Nav className='ml-auto'>{buttons}</Nav>
				</Navbar.Collapse>
				</Container>
		</Navbar>
	);
};

export default React.memo(Header);

Header.propTypes = {
	url: PropTypes.string.isRequired,
	color: PropTypes.string
};
