import React, {Component } from 'react';
import {Button, Row, Image, Col, Container} from 'react-bootstrap'
import styles from './styles.module.css';
import axios from 'axios';
import Teacher from './Teacher.png'

import {connect }from 'react-redux'
import {correctAnswerSelected, incorrectAnswerSelected} from '../../.././store/actions/AnswerCheckActions'
import {stateInitializer} from '../TranslateCore/TranslateCore';

class TranslateSentence extends Component {
  constructor(props){
  super(props);
  this.state = stateInitializer(props, "description", this)

  }

  componentDidMount () {
    if(this.state.inputValue && this.state.inputValue.join(' ') == this.props[0].description){
			this.props.correctAnswerSelected();
		}
		else{
			this.props.incorrectAnswerSelected();
		}

		if(this.props.checkButtonClicked){
			this.setState({
        submittedWordsButtons: [],
        inputValue: []});
	}
  }

  componentDidUpdate () {
    if(this.state.inputValue && this.state.inputValue.join(' ') == this.props[0].description){
			this.props.correctAnswerSelected();
		}
		else{
			this.props.incorrectAnswerSelected();
		}

		if(this.props.checkButtonClicked){
			this.setState({
        submittedWordsButtons: [],
        inputValue: []});
	}
  }

  render () {
  return (

		<div style={{width:'100vw',height:'70vh', backgroundColor:'white', overflow: 'scroll', paddingBottom: '10px'}}>
			<div className={styles.Main}>
				<div style={{    color:'black',fontSize: "35px",
					fontWeight:'bold',
						margin: "auto",
				textAlign: "center",
				width: '100%'}}> Translate this sentence to English:</div>
				<Row className="justify-content-center"><div style={{color:'black', paddingBottom: '5px', fontSize: '30px', fontWeight:'bold'}}>
					{this.props[0].word}

				</div></Row>
				<div style={{margin: "auto",
		textAlign: "center"}}>
		<Container >
		<Row  style={{textAlign: 'center', margin: 'auto'}} className="justify-content-center justify-content-xs-center">
		<Image src={Teacher} style={{width:'250px',height:'250px', textAlign: 'center'}}/>
		</Row>

		</Container>
		<div style={{width:'600px', height:'4px',backgroundColor:'#e5e5e5', marginTop:'-80px',margin: "auto", color:"black"}}></div>
<div style={{color:"black", height:"60px", marginTop:"10px"}}>
	{this.state.submittedWordsButtons}
	</div>
				<div style={{width:'600px', height:'4px',backgroundColor:'#e5e5e5', marginTop:'10px',margin: "auto", marginTop:"20px"}}></div>
				</div>
				<Col>
				<div style={{ margin: "auto",
		textAlign: "center"}}>

</div>
<div style={{ margin: "auto", marginTop:'20px',
textAlign: "center"}}>
		{this.state.wordButtons}
					</div>
					</Col>
			</div>

		</div>

	);}

}


const mapStateToProps = state => {
	return{
		checkButtonClicked: state.checkButtonHasBeenClicked.checkButtonClicked
	}
}
const mapDispatchToProps=(dispatch)=>{
	return {correctAnswerSelected:()=>dispatch(correctAnswerSelected()), incorrectAnswerSelected:()=>dispatch(incorrectAnswerSelected())}


}
export default connect(mapStateToProps,mapDispatchToProps)(React.memo(TranslateSentence));
















