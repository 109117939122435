import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {Row, Image, Col, Container, Button} from 'react-bootstrap';
import styles from './Courses.module.css';
import Header from './Header/Header';
import WordOfDayImage from './wotd.png'
import SoundIcon from './translationIcon.png'
import Category1 from './category1.png';
import Category2 from './category2.png';
import Category3 from './category3.png';
import Category4 from './category4.png';
import Category5 from './category5.png';
import LockIcon from '@material-ui/icons/Lock';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { CircularProgress} from '@material-ui/core';
import * as profileActions from '../../store/actions/profile';
import { connect } from 'react-redux';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Helmet} from 'react-helmet';
import { Autorenew } from '@material-ui/icons';
import { NotNewUser } from '../../store/actions/NewUser';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

function isPlaying(audio) { return !audio.paused; }

function playAudio(audio){
	if (isPlaying(audio)){
		audio.pause()
		audio.currentTime = 0
	}

	audio.play()
}
class Courses extends Component {
	constructor(props){
		super(props)
			this.state = {
				coursesHTML: null,
				courseLang: this.props.match.params.language,
				flag: '',
				words: [],
				WOTD: {},
				order:["Introduction", "Alphabet", "Greetings", "Body Parts", "Locations", "Colors", "Animals","Shopping",
				"Transportation", "Accomodations", "Health/Emergencies",
				 "Weather", "Feelings", "Home", "School", "Descriptions", "Activities",
				 "Sports", "Days of the Week", "Time", "Drinks", "Occupations", "Numbers", "Food"],
				rehydrated: null,
				unorderedCourses: [],
				orderedCourses:[],
				coursesLoaded:false,
				numCourses: 20,
				newUser: this.props.newUser ? true : false


			}
	}

	componentDidMount() {
		if (this.props.token){
			this.props.NotNewUser()
		}
		this.loadOrder();
		this.loadCourses();
		this.newWOTD();

	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.profile !== prevProps.profile) {
			this.loadOrder();
			this.loadCourses();
		}
	}

	loadFlag = () => {

		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";
		const lang = this.state.courseLang

		axios.get(url + '/language/' + lang).then(res => {

			this.setState({flag: res.data.flag});



		});
	};
	// checkLoggedIn(){
	// 	if(!this.props.profile){
	// 		window.location.href = "/logout";
	// 	}
	// }
	startCourse = (id, part, callback) => {
		//Has to be implemented as a callback to ensure
		//that firebase is updated with the courseref BEFORE
		// The window URL is updated.
		//Otherwise the course will not be
		//added to progressItems
		//Callback function here just is 	changeUrl
		this.updateProgressObject(id,part);
		// setTimeout(callback(id), 20000);

	};
	updateProgressObject = (id, part) =>{
		if(part==0){
			part=1;

		const newCourseData = {
						completed: false,
						part:1,
		}

		let profileCourses= new Map();
		// profileCourses.set([course.id],newCourseData)
			profileCourses[id]=newCourseData;

			let languageMap = this.props.profile.progress.progressItems[this.state.courseLang];
			//Courses submap Map
		for (const key2 in languageMap) {
			let courseVar= languageMap[key2];
				//Submap of courses submap (an individual course) key3 should be the course ID
				for (const courseID in courseVar) {
					if(courseVar.size!=0){
						profileCourses[courseID] = courseVar[courseID];
				}
			}

		}

		let progress = {
			currentLanguage: this.state.courseLang,
			progressItems: null
		};
		const profileProgress = JSON.parse(JSON.stringify(this.props.profile.progress));
			profileProgress.progressItems[this.state.courseLang]= {courses:profileCourses};
			profileProgress.currentLanguage=this.state.courseLang;

		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";
		axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
		axios.defaults.xsrfCookieName = 'csrftoken';

		axios.defaults.headers = {
			'Content-Type': 'application/json',
			Authorization: 'Token ' + this.props.token,
		};

		if (this.props.token){
			axios
			.put(url + '/profile/update-progress/', profileProgress)
			.then(res => this.props.updateProfile());
		}
		else{}
		}

	}
	changeUrl = (id) =>{
		let courseUrl = "/learn/"+id;
			window.location.href = courseUrl;

	}

	//Word of the Day Function
	newWOTD = () => {
		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";

		axios.get(url + '/wotd/' + this.state.courseLang).then(res => {
			let newWord  = res.data;
			newWord.word= res.data.word.capitalize();
			newWord.description=res.data.description.capitalize();

			newWord.audio = new Audio(res.data.audio)
			this.setState({
				WOTD:newWord})
		})
	}


loadOrder = () =>{
		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";

	axios.get(url + '/courseorder/').then(res => {
			let order = [];
// let x =0;
			for (let item in res.data){
					order.push(res.data[item]);
							// x++;
			}


		this.setState({order: order})
	})

}
lessonArrowBack = (maxPart, maxUnlockedPart, course, index) =>{
	//Index being the course's position in the orderedCourses array
// TODO
	let part= this.state.orderedCourses[index] ? this.state.orderedCourses[index].part : 0
	if(part-1<=0|| part+1>maxPart||part+1> maxUnlockedPart){
		alert("Oops");
	
}

	//To do: Update OrderedCourses state
	else{
		let x =0;
		let orderedCoursesNew = [];
		while(x< this.state.orderedCourses.length){
			orderedCoursesNew.push(this.state.orderedCourses[x]);
			x++;
		};

		orderedCoursesNew[index].part = part-1;
		this.setState({
			orderedCourses:orderedCoursesNew

		});
	}

}

lessonArrowForward = (part) =>{
// TODO:
alert("Oops");
}
	loadCourses = () => {
		this.loadFlag()
		let numCourses = 0;
		const coursesHTML = [];
		//Created to Control the Order that Courses Appear
		let unorderedCourses = [];
		let pushedIndex =0;


		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";

		axios.get(url + '/courses/' + this.state.courseLang).then(res => {

			let cardBgColor;
			const kLightOrange = "#fff5df";
			const kLightPurple = "##feecfd";
			const kLightRed = "#feeff2";
			const kLightGreen = "#effef3";
			const kLightBlue = "#eff8ff";

			let x =0;
			let courseIMG = Category1;
			//Kept like this for easy checking
			let coursesList=[];

			for (let course of res.data) {
				if(course.availWeb){
				numCourses++;
					axios.get(url + '/course/' + course.id + '/').then(res => {
						this.setState({
							words:res.data.words,
						});
					
						course.part=0;
						course.completed=false;
						course.words=this.state.words;
						course.numWords=0;
						
						coursesList.push(course);
						course.correctIcon =<div style={{backgroundColor:"white", fontWeight:"bold", marginLeft:"250px", marginTop:"-80px", borderRadius:"100%", width:"50px", height:"50px", textAlign:"center"}}>
						 					< LockIcon style={{marginTop:"10px"}}/>
											</div>;
							//Set num words. Necceasry to do like this in case a course has no words
							if(course.words){
								course.numWords=course.words.length;
								}
						let i =0;
						let userStarted = false;
						while (i<this.props.profile.courses.length){


							if(this.props.profile.courses[i].id==course.id){
								userStarted=true;
								course.part=this.props.profile.courses[i].part;
								course.completed=this.props.profile.courses[i].completed;
							}

							i++;
						}

						if(x%1==0){
							courseIMG= Category1;
							cardBgColor=kLightOrange;
						}
						if(x%2==0){
							courseIMG= Category2;
							cardBgColor=kLightPurple;
						}
						if(x%3==0){
							courseIMG= Category3;
							cardBgColor=kLightRed;
						}
						if(x%4==0){
							courseIMG= Category4;
							cardBgColor=kLightGreen;
						}
						if(x%5==0){
							cardBgColor=kLightBlue;
							courseIMG= Category5;
						}

							x++;
								let displayNum;
									if(course.part==0){
											displayNum=0;
										}
									else{
											displayNum=course.part;
											}

					
							
						
						unorderedCourses.push(
							{		courseObject:

										<Row style={{display: 'inline-block', flexDirection: 'row', width:'400px'}}>

										<Col style={{width:'150px', paddingRight:"15px"}} >
										<Image src={courseIMG} style={{maxWidth:"150px", maxHeight:'150px',float: 'left', width: 'auto', height: 'auto'}}/>
										</Col>
										<Col style={{width:'300px'}}>
										<div style={{ marginLeft:"-20px",marginTop:"50px", fontSize:"15px"}}>
											{course.name}
											</div>
											</Col>
											</Row>,

										position:30,
										name:course.name,
										courseData:course,
										part: course.part,
										id: course.id,
										bgColor:cardBgColor,
										displayNum:displayNum,
									}

						);
							

						this.setState({
							unorderedCourses: unorderedCourses,
						});
							this.orderCourses(this.state.unorderedCourses);
							});

						}
			}
			this.setState({
				numCourses: numCourses,
			});
		});
	};

//Function orders courses after courses have been loaded
	orderCourses = (unorderedCourses) => {
		let coursesLoaded = false;
let coursesHTML =[];


		let x =0;
		//Holds the courses order
		let order = this.state.order;
		while(x<unorderedCourses.length){
			let y =0;
			while(y<order.length){
				if(unorderedCourses[x].name==order[y]){
					unorderedCourses[x].position = y;
				}
				y++;
			}
			x++;
		}
		//After they are all assigned sort the array
		let orderedCourses = unorderedCourses.sort(function (a,b){
			return a.position - b.position;
		})
		//set coursesHTML to ordered courses
		 x =0;

		while(x<orderedCourses.length){
			if(x===this.state.numCourses-1){
				coursesLoaded=true;
			}
			let progressVal = orderedCourses[x].displayNum/ Math.ceil(orderedCourses[x].courseData.numWords/5);
			if(progressVal==0){
				progressVal=1;
			}
			else{
				progressVal=progressVal*100;
			}
			let courseText = 	<div style={{ marginTop:"0px", color:'grey', fontSize:'7px'}}>
			<div style={{ marginTop:"-70px", color:'grey', fontSize:'7px', marginLeft:"30px"}}>
Complete Previous Courses to Unlock
</div>
						</div>;
			let correctIcon =
		<div style={{backgroundColor:"white", fontWeight:"bold", marginLeft:"250px", marginTop:"-80px", borderRadius:"100%", width:"50px", height:"50px", textAlign:"center"}}>
				 < LockIcon style={{marginTop:"10px", color:'grey'}}/>
				</div>;
			if(x<4 && orderedCourses[x]){
				let id =orderedCourses[x].id;
				let part = orderedCourses[x].part;

					correctIcon =

					<div>
						
					<Link to={!this.props.newUser ? "/learn"+"/"+id+"/"+part: '/newUser/learn/'+id+"/"+part}>
					<a style ={{cursor:"pointer"}} onClick={() => this.startCourse(id, part, this.changeUrl)}>
					<div  style={{backgroundColor:"white", fontWeight:"bold", marginLeft:"250px", marginTop:"-80px", borderRadius:"100%", width:"50px", marginBottom:'-90px',height:"50px", textAlign:"center"}}>
					 <PlayArrowIcon  style={{marginTop:"10px", color:'#ff6f1a'}}/>
					</div>
					<CircularProgress variant="determinate" style={{color:'#ff6f1a', marginTop:'40px', marginLeft:'250px',}} value={progressVal} size={50} thickness={2}/>
					</a>
					</Link>
				</div>

				courseText=
				<Row> 
						<div style={{marginTop:"-60px", color:'#ff6f1a', fontSize:'15px', marginLeft:"60px", zIndex:'1', width: '130px'}}>
							<ArrowBackIosIcon onClick={() => this.lessonArrowBack(part)} style={{fontSize:'17px'}}/>
							Lesson {orderedCourses[x].displayNum}/{Math.ceil(orderedCourses[x].courseData.numWords/5)}
							<ArrowForwardIosIcon onClick={() => this.lessonArrowForward(part)} style={{ fontSize:'17px'}}/>
					</div>
			</Row>
			}
			else{
				if(orderedCourses[x-1].courseData.completed || orderedCourses[x-1].part>=(orderedCourses[x].courseData.numWords/5)){
					let id =orderedCourses[x].id;
					let part = orderedCourses[x].part;
					correctIcon =
					<Link to={"/learn"+"/"+id+"/"+part}>
					<a style ={{cursor:"pointer"}} onClick={() => this.startCourse(id, part,this.changeUrl)}>
					<div  style={{backgroundColor:"white", fontWeight:"bold", marginLeft:"250px", marginTop:"-80px", borderRadius:"100%", width:"50px", marginBottom:'-90px',height:"50px", textAlign:"center"}}>
					 <PlayArrowIcon  style={{marginTop:"10px", color:'#ff6f1a'}}/>
					</div>
					<CircularProgress variant="determinate" style={{color:'#ff6f1a', marginTop:'40px', marginLeft:'250px',}} value={progressVal} size={50} thickness={2}/>
					</a>
					</Link>
				
				courseText=
				<Row> <div style={{ marginTop:"-60px", color:'#ff6f1a', fontSize:'15px', marginLeft:"40px"}}>
				<ArrowBackIosIcon onClick={() => this.lessonArrowBack(part)} style={{fontSize:'17px'}}/>
			Lesson {orderedCourses[x].displayNum}/{Math.ceil(orderedCourses[x].courseData.numWords/5)}
			<ArrowForwardIosIcon onClick={() => this.lessonArrowForward(part)} style={{ fontSize:'17px'}}/>
			</div>
			</Row>
				}

			}
			coursesHTML[x]=
		<div style={{backgroundColor:orderedCourses[x].bgColor}} className={styles.CourseCard}>
			<div>
			{orderedCourses[x].courseObject}
			</div>
			<div>
			{courseText}
			</div>
			<div>
			{correctIcon}
			</div>

		</div>
			x++;
		}
		//Lastly update the state of ordered courses

		this.setState({
			orderedCourses: orderedCourses,
			coursesHTML: coursesHTML,
			coursesLoaded: coursesLoaded,
		});

	}

	render() {

		let coursesHTML = this.state.coursesHTML;
		let coursesLoaded = this.state.coursesLoaded;
		if (!coursesHTML || !coursesLoaded) {
			coursesHTML = [];
			for (let i = 0; i < 8; i++) {
				coursesHTML.push(<div key={i} className={styles.CourseCardPlaceHolder} >

			</div>);
			}
		}
		return (
			<React.Fragment>
			<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4979330308597711"
     crossorigin="anonymous"></script>
	 		
				<Header url={this.props.match.url} lang={this.state.courseLang} flag={this.state.flag} loggedIn={!this.props.newUser}/>
				<div className=" mt-0 mb-16 mx-auto">
			<h4 style={{color:'white', marginTop:'10vh', marginLeft:'10vw'}}> </h4>
				</div>
					<div className={styles.body}>
					<Container style={{marginBottom: '20px'}}>
					<Row className='justify-content-center' style={{color:'black',fontWeight:'bold',fontSize:'50px'}}>
					{this.state.courseLang}
						<Image style={{height: '40px', width: '40px', marginLeft:'30px', marginTop:'17px'}} src = {this.state.flag}/>

					</Row>
					</Container>

				<div className={styles.Content}>
<div className={styles.WotdCard} style={{margin:'auto'}}>
<div  style={{color:'black', fontWeight:'bold', marginLeft:'200px', marginBottom:'-20px', marginTop:'30px', paddingTop:'20px'}}>
Word of day:
</div>
<Row  style={{color:'black', fontWeight:'bold', marginLeft:'200px', marginTop:'50px'}}>
<Col>
<Row>{this.state.WOTD.word}</Row>
-
<Row> {this.state.WOTD.description}</Row>
</Col>


</Row>
<Image  src={WordOfDayImage} style={{width:'200px',marginTop:'-150px'}}/>
<Col><Image onClick ={()=>playAudio(this.state.WOTD.audio)} src={SoundIcon} style={{height: '30px', width:' 30px', marginTop: '-50px', marginLeft: '320px', cursor:'pointer'}}></Image></Col>
</div>
					<div className={styles.ContainerMain}>
						<div className={styles.RightColumn}>

						{coursesHTML}</div>
					</div>
				</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		profile: state.profile,
		token: state.auth.token,
		newUser: state.newUser.newUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProfile: () => dispatch(profileActions.profileLoad()),
		NotNewUser: () => dispatch(NotNewUser())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Courses);
