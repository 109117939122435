import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

import styles from './Header.module.css';

const Header = props => {
	const url = props.url.split('/')[1];

	let buttons = (
		<React.Fragment>

			 <Nav.Link style={{color: "black"}} href="/settings" className={styles.LogoutBtn}>
			<span>Account Settings</span>
			</Nav.Link>
			<Nav.Link style={{color: "black"}} href="/logout" className={styles.LogoutBtn}>
			<span>Log out</span>
			</Nav.Link>

		</React.Fragment>
	);

	if (url === 'login' || url === 'join' || url=='newUser') {
		const loginBtnActive = url === 'login' ? ` ${styles.ActiveButton}` : '';
		const signUpBtnActive = url === 'join' ? ` ${styles.ActiveButton}` : ` ${styles.SignUpPurple}`;

		buttons = (
			<div className={styles.AuthNavButtonsDiv}>
			<Nav.Link style={{color: "white"}} href="https://blog.mandla.app" target="_blank" className={styles.LogoutBtn}>
		  <span>Blog</span>
		  </Nav.Link>
			</div>
		);
	}

		if ( url === '') {
			const loginBtnActive = url === 'login' ? ` ${styles.ActiveButton}` : '';
			const signUpBtnActive = url === 'join' ? ` ${styles.ActiveButton}` : ` ${styles.SignUpPurple}`;

			buttons = (

				<div className={styles.AuthNavButtonsDiv}>
					<Nav.Link style={{color: "white"}} href="/login" className={styles.LogoutBtn}>
						Login
					</Nav.Link>
					<Nav.Link style={{color: "white"}} href="https://blog.mandla.app" target="_blank" className={styles.LogoutBtn}>
					<span>Blog</span>
					</Nav.Link>
				</div>
			);
		}

	return (
		<Navbar collapseOnSelect expand='sm' className={styles.Header} style={{backgroundColor: "black"}}>
			<Container fluid="sm">
			  <Navbar.Brand className={styles.LogoWrapper} href="/"></Navbar.Brand>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse id='responsive-navbar-nav'>
					<Nav className='ml-auto'>{buttons}</Nav>
				</Navbar.Collapse>
				</Container>
		</Navbar>
	);
};

export default React.memo(Header);

Header.propTypes = {
	url: PropTypes.string.isRequired,
	color: PropTypes.string
};
