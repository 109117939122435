import React from 'react';
import {Button, Row, Image, Col} from 'react-bootstrap'

import Failure from './failure.png'


const IncorrectFooter = props => (



      <Row style={{ margin:'auto',width:'100vw',height:'20vh',backgroundColor:'#FFDFE0', marginBottom:'0'}}>
      <Col style={{marginLeft:'2vw', marginTop:'2vh'}}>
      <Image src={Failure} style = {{width:'90px', height:'90px'}}/>
      {/* <div style={{fontWeight:'bold', color:'#EA2B2B', fontSize:'30px', marginLeft:'20px', marginTop:'60px'}}>
      Incorrect!
      </div> */}
      </Col>
      <Col className='ml-auto'>
          <Button onClick={props.next} style={{width:'16vw',marginTop:'4vh', marginLeft:'30vw', marginRight:'2vw', backgroundColor:'#FF4B4B', border:'none',borderRadius:'30px'}} >
    Next
          </Button>
          </Col>

    </Row>
		);



export default React.memo(IncorrectFooter);
