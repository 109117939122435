import * as actionTypes from '../actions/actionTypes';
import * as profileActions from '../../store/actions/profile';
import { updateObject } from '../utility';

const initialState = {
	loading: true,
	username: null,
	level: '1',
	points: '0',
	wordsLearned: '0',
	languages: [],
	progress: {
		progressItems: {}
	},
	firstName:"First",
	lastName:"Last",
	email:"email",
	location:"United States",
	startingDate:"2021",
	progressMap:null,
	courses: []
};

const profileStart = state => {
	return updateObject(state, {
		loading: true,
	});
};

const profileLoaded = (state, action) => {
	return updateObject(state, {
		loading: false,
		username: action.username,
		courses: action.courses,
		languages: action.languages,
		level: String(action.level),
		points: String(action.points),
		wordsLearned: String(action.wordsLearned),
		progress: action.progress,
		firstName:action.firstName,
		lastName:action.username,
		email:action.email,
		location:action.location,
		startingDate:action.startingDate,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.PROFILE_START:
			return profileStart(state);
		case actionTypes.PROFILE_LOADED:
			return profileLoaded(state, action);
		default:
			return state;
	}
};

export default reducer;
