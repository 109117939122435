import React from 'react';

import CorrectFooter from './CorrectFooter'
import IncorrectFooter from './IncorrectFooter'
import NeutralFooter from './NeutralFooter'
import IntroductionFooter from './IntroductionFooter'

const GameFooter = (props) => {
let footer = <NeutralFooter {...props}/>;
if(props.index==0){
	footer = <NeutralFooter {...props}/>
}
if(props.index==1){
	footer = <CorrectFooter {...props}/>
}
if(props.index==2){
	footer =  <IncorrectFooter {...props}/>
}
if (props.index==3){
	footer = <IntroductionFooter {...props}/>
}
return (
	<div>
	{footer}
	</div>
);

}






export default GameFooter;
