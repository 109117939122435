import React from 'react';
import {Button} from 'react-bootstrap'
import styles from './styles.css';


const GameHeader = (props) => {
	const progress = (100 * (props.totalTurns - props.turns)) / props.totalTurns;
	const progressWidth = { width: progress + '%' };


		return (
			<div className={styles.Header}>


<div style={{width:'100vw',height:'10vh', backgroundColor:'white'}}>
<Button onClick={props.close} style={{color:'#afafaf', backgroundColor:'white', border:'none'}}>
X
</Button>
</div>
</div>



);
}

export default React.memo(GameHeader);
