import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import {Row} from 'react-bootstrap';
import styles from './Home.module.css';
import Header from './Header/Header';
import Welcome from '../../components/Home/Welcome/Welcome';
import LanguageCard from '../../components/Home/LanguageCard/LanguageCard';
import FakeCardCollection from '../../components/FakeCardCollection/FakeCardCollection';
import {Link} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import LoadingRing from '../../components/LoadingRing/LoadingRing';
// import FakeCard from '../../components/FakeCard/FakeCard';

import * as profileActions from '../../store/actions/profile';

//added
import { NotNewUser } from '../../store/actions/NewUser';
import "firebase/firestore";
import { firebaseObj } from "../../firebase"
import { StarTwoTone } from '@material-ui/icons';



export class Home extends Component {

	static propTypes = {
		profile: PropTypes.object.isRequired,
	};

	state = {
		languagesHTML: (
			<React.Fragment>
				{/* <div style={{width: '940px', height:'540px', display:'flex'}}> */}
				<FakeCardCollection number={6} />
				{/* </div> */}
			</React.Fragment>
		),
		modal: false
	};

	componentDidMount() {
		this.props.NotNewUser()
		document.title = 'Dashboard - Mandla';
		firebaseObj.auth().onAuthStateChanged( (user) =>  {
			if (user) {
				user.getIdToken(true).then( (idToken)  => {  // <------ Check this line
					// console.info("firebaseObj shows the Firebase idToken now ", idToken); // It shows the Firebase token now
					localStorage.setItem("token", idToken)
					//load profile with the new token
					this.props.updateProfile(idToken)		
				});
			}
		});
		
	}

	componentDidUpdate(prevProps) {
			if (this.props.profile.languages !== prevProps.profile.languages){
				//after state change, this method will be triggered and courses will load
				this.loadCourses();
			}
		
	}

	loadCourses = (props = this.props) => {
		
		var user = firebaseObj.auth().currentUser;

		if (user){
			let languagesHTML = [];
			if (props.profile.languages.length !== 0) {
				var languages = props.profile.languages
				languages.sort((a, b)  => {
					return a.name.localeCompare(b.name)
				});
				for (let language of languages) {
					languagesHTML.push(
						<LanguageCard
							key={language.id}
							language={language}
							// learn={this.learn}
							// course={course}
							// quitCourse={this.quitCourse}
						/>,
					);
				}
			} else {
				
				languagesHTML = <Welcome />;
				
			}
			this.setState({
				languagesHTML: languagesHTML,
			});
	}
	else{
		console.log("Reloading due to missed auth.")
		window.onload = function() {
			if(!window.location.hash) {
				window.location = window.location + '#loaded';
				window.location.reload();
			}
		}
	}
};

	learn = language => {
		this.props.history.push('/courses/' + language);
	};

	quitCourse = courseId => {
		axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
		axios.defaults.xsrfCookieName = 'csrftoken';

		axios.defaults.headers = {
			'Content-Type': 'application/json',
			Authorization: `Token ${this.props.token}`,
		};

		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";

		axios.post(url + '/profile/remove/' + courseId + '/').then(res => {
			this.props.updateProfile(this.props.token);
		});
	};

	loadModal = () =>{

		if (document.referrer === 'https://mandla.app/login' || document.referrer === 'https://mandla.app/join'){
			this.setState({modal: true})
		}
	}

	render() {

		return (
			<React.Fragment>
				
				<Header url={this.props.match.url} profile={this.props.profile} />

					<div className ={styles.Body}>

				<div className={styles.Content}>

				<Row style={{marginLeft:'60px', textAlign:'center'}}>

				<div style = {{color:'white', fontSize:'30px'}}>

				</div>
					</Row>

				<div style = {{color:'white', fontSize:'30px', fontWeight:'bold'}}>
				My Languages
				</div>


					<div className={styles.ContainerMain}>


						<div className={styles.RightColumn}>


							{this.state.languagesHTML}
							 {/* <FakeCardCollection number={6} /> */}

							</div>

					</div>
					<Link to="/languages">
	<AddIcon style={{color:'black', backgroundColor:'white', borderRadius:'50px', width:'50px', height:'50px', marginBottom:'0'}}/>
	</Link>
</div>
						</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		profile: state.profile,
		token: state.auth.token,
		langAboutPopupWasOpened:state.langAboutPopupWasOpened,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProfile: (token) => {dispatch(profileActions.profileLoad(token))},
		NotNewUser: ()=> dispatch(NotNewUser()),

	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Home);
