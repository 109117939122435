import React from "react";
import {Row, Col, Container, Image} from 'react-bootstrap';
// import styles from './FakeCard.module.css';
import FakeCard from './FakeCard';






export default class FakeCardCollection extends React.Component {
    render(){
        let Collection = [];
        for (let i =0; i<this.props.number; i++){
            Collection.push(<FakeCard />);
        }
        
        return(
            <div style={{justifyContent:'center', width: 'inherit', display: 'flex', flexWrap:'wrap', margin: '30px'}}>
                {Collection}
            </div>
                
            
        ); 
    }
    							
}