const initialState ={
    selectedAnswer:null
}


 const AnswerCheckReducer =(state=initialState,action)=>{
     const {type,payload}=action
switch(type){
    case "Correct":

        return {selectedAnswer:true};

   case "NotCorrect":
        return {...state,selectedAnswer:false}

    default:
        return {...state,selectedAnswer:false}
}
}
export default AnswerCheckReducer;
