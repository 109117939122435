export const correctAnswerSelected = () => (dispatch) => {
    dispatch({
        type: "Correct",
    });
}

export const incorrectAnswerSelected = () => (dispatch) => {
    dispatch({
        type: "NotCorrect",
    });
}
