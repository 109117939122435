import React from 'react';
import {Row, Col, Image, Container} from 'react-bootstrap';
import styles from './FakeCard.module.css';
import classNames from 'classnames';




export default class FakeCard extends React.Component {
    render(){
        const bigClass = classNames(styles.BigBlock, styles.shine)
        return(
            // <Container fluid className={styles.Back}>

            <div className={styles.LanguageCard}>
                {/* <div className={styles.shine}> */}
								<div className={styles.Circle} ><div className={styles.shine}></div> </div>
                                
                                <div className={styles.BigBlock}><div className={styles.shine}></div></div>
                            {/* <div className={styles.BigBlock}> */}
                                <div className={styles.SmallBlock}><div className={styles.shine}></div> </div>
                            {/* </div> */}
							{/* </div> */}
                            </div>
            // </Container>
        );

    }
}