
export const NewUser=()=>async(dispatch)=>{
    dispatch({
        type:"ClickedStartLearning"
    })
}
export const NotNewUser=()=>async(dispatch)=>{
    dispatch({
        type:"NotClickedStartLearning"
    })
}
