import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {Image, Col, Row} from 'react-bootstrap';
import styles from './Courses.module.css';
// import Header from './Header/Header'; settings header
import location from './location.png';
import WordOfDayImage from './wotd.png'
import {Helmet} from 'react-helmet'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import * as profileActions from '../../store/actions/profile';
import { connect } from 'react-redux';
import firebase from "firebase/app";
import { NotNewUser } from '../../store/actions/NewUser';
import Header from '../Home/Header/Header';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';




class Settings extends Component {
	state = {
		coursesHTML: null,
		courseLang: this.props.match.params.language,

			email: '',
			password: '',
			emailInputClasses: styles.Input,
			passwordInputClasses: styles.Input,
			errorMessage: null,

	};

	componentDidMount() {
		this.props.NotNewUser()
		this.loadCourses();
		setInterval(this.checkLoggedIn.bind(this), 100000);
	}

	checkLoggedIn(){
		if(!this.props.profile){
			window.location.href = "/logout";
		}
	}
	loadCourses = () => {
		const coursesHTML = [];

		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";

		axios.get(url + '/courses/' + this.state.courseLang).then(res => {
			let cardBgColor;
			const kLightOrange = "#FFF4E0";
			const kLightPurple = "#FFEDFE";
			const kLightRed = "#FFF0F2";
			const kLightGreen = "#F0FFF3";
			const kLightBlue = "#F0F9FF";

			let courseCardColor = kLightOrange;


		});
	};



		resetPassword = () => {

			var email = this.state.email
			const auth = firebase.auth()

			auth.sendPasswordResetEmail(email).then(() => {
				alert("A Reset Link has been sent to your email")
			}).catch((err) => {
				if (err.code === 'auth/invalid-email'){
					alert("Please enter a valid email address.")
				}
				else{
					console.error(err)
				}
			}
			)


		}

		onKeyDown = event => {
			if (event.key === 'Enter') {
				this.resetPassword();
			}
		};

		inputChange = event => {
			const name = event.target.name;
			if (this.state[name + 'InputClasses'] === styles.Input + ' ' + styles.Error) {
				this.setState({
					[name]: event.target.value,
					[name + 'InputClasses']: styles.Input,
				});
			} else {
				this.setState({ [name]: event.target.value });
			}
		};


		handleNaming = profile => {
			// console.log('pss', profile);
			// if not firstName:
			if (!this.state.firstName){
				this.setState({firstName : profile.username});
			}
			if (!this.state.email){
				this.setState({email: profile.username});
			}
		}


	render() {
		// this.handleNaming(this.props.profile);


		return (
			<React.Fragment>
				{/* <Header url='/Home' lang={this.state.courseLang} flag={this.props.flag}/> */}
					{/* {console.log('sp',this.props.profile)} */}
					<Helmet>
					<meta name="apple-itunes-app" content="app-id=1567445203, app-argument=myURL" /> 
					</Helmet>
					<Header url={this.props.match.url} profile={this.props.profile} color='white' textColor='black' LogoSwitch={true} />
					
					<div className={styles.body}>
					<Row><div className={styles.Circle}> 
						<Col className={styles.CircleCol}><span className={styles.CircleText}>{this.props.profile.loading? null:this.props.profile.username[0].toUpperCase()}</span></Col>
						</div>
						<Col className={styles.NameCol}><Row className={styles.NameText}>{this.props.profile.loading? null : this.props.profile.username}</Row>
						{/* {this.props.profile.loading? null:this.props.profile.firstName+' '+this.props.profile.lastName} */}
							<Row className={styles.EmailText}>{this.props.profile.loading? null:this.props.profile.email}</Row>
							{/* {this.props.profile.loading? null:this.props.profile.email+} */}
							<Row className={styles.LocationRow}><Col xs={4}></Col><Col xs={2}><Image className={styles.LocationIcon} src={location} width='15px' height='15px'/></Col></Row>
							{/* <Row></Row> */}
							</Col>
							</Row> 
						
						<hr />
						<Row><div className={styles.LangBox}>
							{this.props.profile.languages.map(el => (
								<div className={styles.FlagComp}> 
								 <Link to={"/About/" + el.name} ><Image className={styles.Img} src={el.flag} width='40px' height='40px'/></Link>
								<div className={styles.LangHolder}><span className={styles.LangName}>{el.name.slice(0,3)}</span></div>
								</div>
							))}
							</div></Row>
					</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		profile: state.profile,
		token: state.auth.token,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProfile: () => dispatch(profileActions.profileLoad()),
		NotNewUser: () => dispatch(NotNewUser())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(React.memo(Settings));




// return (
// 	<React.Fragment>
// 		<Header url={this.props.match.url} lang={this.state.courseLang} flag={this.props.flag}/>

// 			<div className={styles.body}>
// 			<div className=" mt-0 mb-16 mx-auto">
// 	<h4 style={{color:'white', marginTop:'10vh', marginLeft:'10vw'}}> </h4>
// 		</div>
// 		<div className={styles.Content}>
// <div className={styles.WotdCard} style={{margin:'auto'}}>
// <div  style={{color:'black', fontWeight:'bold', marginLeft:'200px', marginBottom:'-20px', marginTop:'30px', paddingTop:'20px'}}>
// Hi there
// </div>
// <div  style={{color:'black', fontWeight:'bold', marginLeft:'200px', marginTop:'30px'}}>
// {this.props.profile.username}
// </div>
// <Image src={WordOfDayImage} style={{width:'200px',marginTop:'-90px'}}/>
// <div  style={{color:'black', fontWeight:'bold', marginTop:'30px'}}>
// Username: {this.props.profile.username}
// </div>
// <div  style={{color:'black', fontWeight:'bold',  marginTop:'30px'}}>
// Email: {this.props.profile.email}
// </div>
// <div  style={{color:'black', fontWeight:'bold',  marginTop:'30px'}}>
// <Popup trigger={<div style = {{color:'blue', cursor:'pointer', textDecoration:'underline'}}>Reset Password</div>} position="center">
// <div style={{width:'500px', height:'200px', border:'none', boxShadow:'none'}}>	<div className={styles.WhiteBox}>
// 	<div className={styles.LoginTitle}>Reset Password</div>

// 	<div className={styles.Label}>Email:</div>
// 	<input
// 		name="email"
// 		onChange={this.inputChange}
// 		className={this.state.emailInputClasses}
// 	/>


// 	<div onClick={this.resetPassword} className={styles.LoginButton}>
// 		Send Password Reset Email
// 	</div>

// </div></div>
// </Popup>
// <Link></Link>
// </div>
// </div>
// 			<div className={styles.ContainerMain}>
// 				<div className={styles.RightColumn}>

// 				</div>
// 			</div>
// 		</div>
// 		</div>
// 	</React.Fragment>
// );