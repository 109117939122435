export const checkButtonHasBeenClicked=()=>async(dispatch)=>{
    dispatch({
        type:"Clicked"
    })
}
export const checkButtonHasNotBeenClicked=()=>async(dispatch)=>{
    dispatch({
        type:"NotClicked"
    })
}
