import React from 'react';
import classNames from 'classnames';
import Button from '../elements/Button';
import Image from '../elements/Image';
import {Col} from 'react-bootstrap';
import logingirl from '.././img/logingirl.png';
import styles from '.././LandingPage.module.css';
import { Link } from 'react-router-dom';
import LanguageSlider from '../LanguageSlider/languageslider'



const FirstHalf= props => {

      const outerClasses = classNames(
        'hero section center-content',
         'has-top-divider',
         'has-bottom-divider',
      'has-bg-color',
        'invert-color',

      );

      const innerClasses = classNames(
        'hero-inner section-inner',

      );


    return (
      <section

        className= {styles.FirstHalf}
      >
        <div className="container-sm" style={{paddingBottom: '100vh'}}>
          <div className={innerClasses}>
            <div className=" mt-0 mb-16 mx-auto">
            <Image src={logingirl} width={420} className="mx-auto"/>
            <div   className= {styles.TitleColor}>
              <h1 className="mt-0 mb-16 reveal-from-bottom mx-auto " data-reveal-delay="200">
              Learn Languages with Sebmita
              </h1>
              </div>
              <div className="container-xs">
                <div className="reveal-from-bottom" data-reveal-delay="600">
                  <Col className={styles.StartLearning}>
                    <Link to={props.newUser ? "/newUser/Languages": "/join"} >
                      <div >
                    <Button tag="a" wideMobile className="mx-auto mb-10 rounded-pill ">
                      Start Learning
                      </Button>
                      </div>

                      </Link>
                      </Col>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <LanguageSlider/> */}
        <div>
        </div>
      </section>
    );


}
export default FirstHalf
