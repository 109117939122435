import { Row, Container, Image, Button, Col, Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import web from './img/web.png';
import contact from './img/contact.png';
import wave from './img/wave.png';
import person1 from './img/person1.png';
import person2 from './img/person2.png';
import contribute from './img/contribute.png';
import mobile from './img/mobile.png';
const CardsRow= props => {
  return (
  <Row >

<Col>
        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
          <div className="tiles-item-inner">
            <div className="">
          <Image src={mobile}rounded className="mx-auto" />
            </div>

            <div className="testimonial-item-footer text-xs mt-30 mb-0 ">
            <h4 style={{marginTop:40,color: 'black',}}>Convenient</h4>
            <p className="text-sm mb-0">
             Improve your language skills in just 5 minutes a day through our interactive bite sized lessons!
                </p>
              <span className="testimonial-item-link">

              </span>
            </div>
          </div>
        </div>
</Col>
<Col>
        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
          <div className="tiles-item-inner">
            <div className="">
          <Image src={web}rounded  className="mx-auto"/>
            </div>
            <div className="testimonial-item-footer text-xs mt-32 mb-0 ">
              <h4 style={{color: 'black',}}>Community</h4>
            <p className="text-sm mb-0">
        Join our thousands of global users already on the app!
                </p>
              <span className="testimonial-item-link">

              </span>
            </div>
          </div>
        </div>
  </Col>
  <Col>
  <div className="tiles-item reveal-from-right" data-reveal-delay="200">
    <div className="tiles-item-inner">
      <div className="">
    <Image src={contribute}rounded  className="mx-auto"/>
      </div>
      <div className="testimonial-item-footer text-xs mt-32 mb-0 ">
        <h4 style={{color: 'black',}}>Contribute</h4>
      <p className="text-sm mb-0">
      Join Mandla’s amazing Volunteer team by
  filling out <a href="https://docs.google.com/forms/d/e/1FAIpQLSelbhcYya5lcr6ufVypdNLVfpBTwU-4LjRBhkfHFmHkVp6dYQ/viewform" target="_blank">this</a> application.
          </p>
        <span>

        </span>
      </div>
    </div>
  </div>
  </Col>
<Col>
<div className="tiles-item reveal-from-right" data-reveal-delay="200">
  <div className="tiles-item-inner">
    <div className="">
     <Image src={contact}rounded className="mx-auto"/>
    </div>
    <div className="testimonial-item-footer text-xs mt-32 mb-0 ">
      <h4 style={{color: 'black',}}>Contact</h4>
    <p className="text-sm mb-0">
    Anything else?
Contact us at
<a href="mailto:contact@mandla.app"> contact@mandla.app</a>
        </p>
      <span>

      </span>
    </div>
  </div>
</div>
</Col>

  </Row>);
}
export default CardsRow;
