import MetaTags from 'react-meta-tags'

const Meta= props => {
  return (
<MetaTags>
   <title>Learn Languages on Sebmita!</title>
   <meta id="meta-description" name="description" content="Learn Languages on Sebmita!" />
   <meta id="og-title" property="og:title" content="Learn Languages on Sebmita" />
   <meta name="apple-itunes-app" content="app-id=1567445203, app-argument=myURL" /> 
 </MetaTags>

);
}
export default Meta
