import React, {useState} from 'react';
import {Row, Image,Container} from 'react-bootstrap'
import Teacher from './Teacher.png';
import styles from './StudyTheWord.module.css';
import soundIcon from './img/translationIcon.png';
import ReactAudioPlayer from 'react-audio-player';
import {connect }from 'react-redux'
import {correctAnswerSelected, incorrectAnswerSelected} from '../../.././store/actions/AnswerCheckActions'

function isPlaying(audio) { return !audio.paused; }

function playAudio(audio){
	if (isPlaying(audio)){
		audio.pause()
		audio.currentTime = 0
	}

	audio.play()
}


const StudyTheWordPhrases = (props) => {

	var repeatWordAudio = new Audio (props[0].audio)

	const select = (audio) => {
		playAudio(audio)
	}

	props.correctAnswerSelected();
	return(

	<div style={{width:'100vw',height:'70vh', backgroundColor:'white', overflow: 'scroll'}}>
		<div >

		<div className={styles.challenge + styles.challengeTranslate}>
				<h1 className={styles.challengeHeader} styles={{marginTop:'70px'}}>
			Study the Following </h1>
				</div>

<Container>
<Row className="justify-content-center">
<div className={styles.WordCard}>

	<div className={props[0].context ? styles.WordCardInner : null}>
		<div className={props[0].context ? styles.WordCardFront : null}>
		<Image src={Teacher} style={{  width:'170px', height:'140px', margin:'auto', marginTop:'60px'}}/>
		</div>
		<div className={props[0].context ? styles.WordCardBack : null}>
		<span className={styles.Context}><strong>{props[0].context}</strong> </span>
		</div>
	</div>

{/*<div className={styles.soundIcon}>

<img className={styles.soundIconImg}src={soundIcon}/></div>*/}
</div>
</Row>
<ReactAudioPlayer
  src={props[0].audio}
  autoPlay
style={{backgroundImg:{soundIcon}}}
className={styles.playAudio}
/>

<Row style={{paddingTop: '10px'}}className="justify-content-center">
<div className={styles.Word}>{props[0].word}</div>
</Row>
<Row className="justify-content-center">
<div className={styles.orangeLine}/>
</Row>
<Row className="justify-content-center">
<div className={styles.Description}>{props[0].description}</div>
</Row>
<Row className="justify-content-center">
<Image onClick ={()=>select(repeatWordAudio)} src={soundIcon} style={{marginTop: '5px', height: '25px', width:' 25px', cursor:'pointer'}}></Image>
</Row>







</Container>
		</div>
	</div>

);
}
const mapDispatchToProps=(dispatch)=>{
	return {correctAnswerSelected:()=>dispatch(correctAnswerSelected()), incorrectAnswerSelected:()=>dispatch(incorrectAnswerSelected())}


}
export default connect(null,mapDispatchToProps)(React.memo(StudyTheWordPhrases));
