import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import firebase from 'firebase';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import NewUserReducer from './store/reducers/NewUser';
import authReducer from './store/reducers/auth';
import profileReducer from './store/reducers/profile';
import AnswerCheckReducer from './store/reducers/AnswerCheckReducer'
import CheckButtonReducer from './store/reducers/CheckButtonReducer'
import LangAboutPopupReducer from './store/reducers/LangAboutPopupReducer'
import ScoreReducer from './store/reducers/ScoreReducer'


const cors = require('cors')({ origin: "*", credentials: true, methods: "GET" });
const analytics = firebase.analytics()


const rootReducer = combineReducers({
	selectedAnswer:AnswerCheckReducer,
	checkButtonHasBeenClicked:CheckButtonReducer,
	langAboutPopupWasOpened: LangAboutPopupReducer,
	userScore:ScoreReducer,
	auth: authReducer,
	profile: profileReducer,
	newUser: NewUserReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

const app = (
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
