import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styles from './LanguageCard.module.css';
import {Image} from 'react-bootstrap';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import LanguageOptionsMenu from './LanguageOptionsMenu';


class LanguageCard extends Component{
    state = {
		modal: false,
	};

	openModal = () => {
		this.setState({ modal: true });
	};

	closeModal = () => {
		this.setState({ modal: false });
	};

    render(){

        const language = this.props.language

        if (this.props.loading) {
			return <div className={styles.LanguageCard + ' ' + styles.LoadingBox} />;
		}
        else{
            //TODO: add a progress bar in the future

        }
        let closeButton;
    if(window.location.pathname=="/home"||window.location.pathname=="/Home"){
      closeButton= <div style={{color:"white", fontWeight:"bold", cursor:"pointer"}}>
      <a>
      <Popup  trigger={<MoreVertIcon style={{marginLeft:"190px", marginBottom:"-150px"}}/>} position="right center">
   <div ><LanguageOptionsMenu lang={this.props.language.name} flag={this.props.language.flag} region={this.props.language.region} about={this.props.language.about} learnerCount={this.props.language.learnerCount}/></div>
 </Popup>

      </a>

      </div>

    }
        return(
            <div className={styles.RightColumn}>
                {this.state.modal && (
						<Suspense fallback={null}>
						</Suspense>

					)}

					<div className={styles.ScaleAnimation}>
          {closeButton}
          <Link key={language.id} to={'/courses/' + language.name} className={styles.LanguageCard}>
							<div>
								<Image style={{display: 'inline'}}src={language.flag} width={70}rounded   />
							</div>
							<div >{language.name}</div>
							<div className={styles.NextUpLabel}>

								<div>Continue Learning</div>
								{/* <div className={styles.NextUpArrow} /> */}

							</div>
              		</Link>
					</div>

            </div>
        )
    }
}

export default LanguageCard;

const conditionalCheck = function(props, propName, componentName) {
	if (!props.loading && !props[propName]) {
		return new Error(
			'Invalid prop `' +
				propName +
				'` supplied to' +
				' `' +
				componentName +
				'`. Validation failed.',
		);
	}
};


LanguageCard.propTypes = {
	loading: PropTypes.bool,
	language: conditionalCheck
};

LanguageCard.defaultProps = {
	loading: false,
};
