import React, {Component} from 'react';
import styles from './LoadingRing.module.css';






class LoadingRing extends Component{
    render(){
        return(
            // <div className={styles.ringPath}>
            //     <div className={styles.ringSpinner}></div>
            // </div>
            // ........................
            <div className={styles.Loader}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            
        );
    }
}



















export default LoadingRing;