import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import {Row} from 'react-bootstrap';
import styles from './Home.module.css';
import Header from './Header/Header';
import {Link} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import LoadingRing from '../components/LoadingRing/LoadingRing';
import {Helmet} from "react-helmet";
import * as profileActions from '../store/actions/profile';

//added
import { NotNewUser } from '../store/actions/NewUser';
import "firebase/firestore";
import { firebaseObj } from "../firebase"
import FakeCardCollection from '../components/FakeCardCollection/FakeCardCollection';

export class Home extends Component {
	static propTypes = {
		profile: PropTypes.object.isRequired,
	};

	state = {
		languagesHTML: (
			<React.Fragment>
				<FakeCardCollection number={6} />

			</React.Fragment>
		),
		modal: false
	};

	componentDidMount() {
		window.location.href = "/Home";
	}

	componentDidUpdate(prevProps, prevState) {


	}


	learn = language => {
		this.props.history.push('/courses/' + language);
	};

	quitCourse = courseId => {
		axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
		axios.defaults.xsrfCookieName = 'csrftoken';

		axios.defaults.headers = {
			'Content-Type': 'application/json',
			Authorization: `Token ${this.props.token}`,
		};

		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";

		axios.post(url + '/profile/remove/' + courseId + '/').then(res => {
			this.props.updateProfile();
		});
	};



	render() {


		return (
			<React.Fragment>
				<Helmet>
				<meta name="apple-itunes-app" content="app-id=1567445203, app-argument=myURL" /> 
				</Helmet>
				<Header url={this.props.match.url} profile={this.props.profile}/>

					<div className ={styles.Body}>

				<div className={styles.Content}>

				<Row style={{marginLeft:'60px', textAlign:'center'}}>

				<div style = {{color:'white', fontSize:'30px'}}>

				</div>
					</Row>

				<div style = {{color:'white', fontSize:'30px', fontWeight:'bold'}}>
				My Languages
				</div>


					<div className={styles.ContainerMain}>


						<div className={styles.RightColumn}>


							{this.state.languagesHTML}

							</div>

					</div>
					<Link to="/languages">
	<AddIcon style={{color:'black', backgroundColor:'white', borderRadius:'50px', width:'50px', height:'50px', marginBottom:'0'}}/>
	</Link>
</div>
						</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		profile: state.profile,
		token: state.auth.token,
		langAboutPopupWasOpened:state.langAboutPopupWasOpened,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProfile: () => dispatch(profileActions.profileLoad()),
		NotNewUser: ()=> dispatch(NotNewUser()),

	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Home);
