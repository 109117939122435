import React, {Component} from 'react';
import {Image, Button, Row} from 'react-bootstrap';
import styles from './SessionComplete.module.css';
import EndGif from './correctgif.gif';
import { connect } from 'react-redux';
import * as profileActions from '../../../store/actions/profile';
import axios from 'axios';
import {Link} from 'react-router-dom';
import firebase from 'firebase';
import { NewUser, NotNewUser } from '../../../store/actions/NewUser';
import { firebaseObj } from "../../../firebase"
class SessionComplete extends Component{
	state ={
		message: "You did not receive a passing score. ",
		score:0,
		passed:false,
		continueURL:"/join"
	}

	componentDidMount() {
		if(this.state.score>this.props.denom){
			this.setState({
				score:this.props.denom,
	});

		}
		var user = firebaseObj.auth().currentUser;
//Firebase API to check if a user is logged in , needed to implment rerouting to sing up page if
//Its a new user using lessons that doens't yet have an account
			if (user) {
					let goToURl = "/courses/" + this.props.lang;

					this.setState({
						continueURL:goToURl,
					}, () =>
					console.log(this.state.continueURL));;

					//Have to multiply by 100 otherwise Math.ceil will make it 1 and every score  will pass
					if(Math.ceil((this.props.score*100)/this.props.denom)>=(this.props.passingpercentage*100)){
						this.updateCourseCompletionDetails();
						if(this.state.score>this.props.denom){
							this.setState({
								score:this.props.denom,
					});

						}
						this.setState({
							passed:true,
				});

			}
				} else {
						if(Math.ceil((this.props.score*100)/this.props.denom)>=(this.props.passingpercentage*100)){
							this.updateCourseCompletionDetails();

							if(this.state.score>this.props.denom){
								this.setState({
									score:this.props.denom,
						});

							}



							this.setState({
								passed:true,
					});
				}
  				// No user is signed in.
				}



let result = this.props.score/this.props.denom;

if(this.state.score<=this.props.denom){
	this.setState({
	score:this.props.score
	});

}



	}
	componentDidUpdate(){

	}

	updateCourseCompletionDetails(){
		this.setState({
		message:"Lesson Complete!"
		});
		let course=this.props.course;
		course.id = this.props.courseID;



			let profileCourses= new Map();
			// profileCourses.set([course.id],newCourseData)


				let languageMap = this.props.profile.progress.progressItems[course.teaching];
				//Courses submap Map
			for (const key2 in languageMap) {
				let courseVar= languageMap[key2];
				let courseDataFromDatabase=courseVar[course.id];
				let newPartVal;
				let completionStatus;
				if((courseDataFromDatabase.part+1)>=Math.ceil(this.props.NumWords/5)){
					completionStatus=true;
					newPartVal=Math.ceil(this.props.NumWords/5);
					const analytics = firebase.analytics()
					analytics.logEvent('courseCompletedWeb', { name: 'courseCompletedWeb'});
				}
				else{
					completionStatus=false;
					newPartVal=courseDataFromDatabase.part+1;
				}
				const newCourseData = {
								completed: completionStatus,
								part:newPartVal,
				}
	profileCourses[course.id]=newCourseData;


					for (const courseID in courseVar) {
						if(courseVar.size!=0 && courseID!= course.id){
							profileCourses[courseID] = courseVar[courseID];
					}



				}

			}



			let progress = {
				currentLanguage: course.teaching,
				progressItems: null
			};
			const profileProgress = JSON.parse(JSON.stringify(this.props.profile.progress));
				profileProgress.progressItems[course.teaching]= {courses:profileCourses};
				profileProgress.currentLanguage=course.teaching;

			const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";
			axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
			axios.defaults.xsrfCookieName = 'csrftoken';

			axios.defaults.headers = {
				'Content-Type': 'application/json',
				Authorization: 'Token ' + this.props.token,
			};

			axios
				.put(url + '/profile/update-progress/', profileProgress)
				.then(res => this.props.updateProfile());



	}
	render() {
			return (
				<React.Fragment>
					{this.props.courseFinished ? (
						<div className={styles.Content}>You have mastered the material!</div>
					) : (
						<div className={styles.Content}>{this.state.message}
						Your score was	<div> {this.state.score}/{this.props.denom} </div>
						</div>

					)}
					<div>
					<Image src={EndGif} style={{width:'300px', margin:'auto', height:'400px'}}/>
					<Row style={{width:'100vw'}}>
					<div style={{textAlign:'center',margin:'auto'}}>
					<Link to = {this.state.continueURL}>
						<Button style={{  width:'200px' }}>Continue</Button>
						</Link>
						</div>
						</Row>

					</div>
				</React.Fragment>
			);

	}
}


const mapStateToProps = state => {
	return {
		profile: state.profile,
		token: state.auth.token,
		newUser: state.newUser.newUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProfile: () => dispatch(profileActions.profileLoad()),
		newUser: () => dispatch(NewUser()),
		notNewUser: () => dispatch(NotNewUser())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(React.memo(SessionComplete));
