import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {Image} from 'react-bootstrap'
import styles from './Interest.module.css'
import Culture from '../culture.png'
import Work from '../work.png'
import School from '../school.png'
import Travel from '../travel.png'
import Friends from '../friends.png'
import Other from '../other.png'


const Interest = forwardRef((props, ref) => {

    const [answer, setAnswer] = useState("")

    useImperativeHandle(ref, () => ({
        getData() {
            
            return answer
        }

      }));

    return (
        <div class='FormBody' >
           <div style={{textAlign: 'center'}}> <h2 class='QuestionHeader' > Why are you interested in learning {props.language}?</h2></div>
            <div className={styles.ContainerMain}>
                <button onClick={()=>setAnswer('CulturalImmersion')} style={{font: 'inherit'}} className={styles.LanguageCard}>
                    <div >
                        <Image src={Culture} width={70}rounded   />
                    </div>
                    <div >Cultural Immersion</div>
                </button>

                <button onClick={()=>setAnswer('School')} style={{font: 'inherit'}}className={styles.LanguageCard}>
                    <div >
                        <Image src={Work} width={70}rounded   />
                    </div>
                    <div style={{font: 'inherit'}}>School</div>
                </button>

                <button onClick={()=>setAnswer('Work')} style={{font: 'inherit'}}className={styles.LanguageCard}>
                    <div >
                        <Image src={School} width={70}rounded   />
                    </div>
                    <div >Work</div>
                </button>


                <button onClick={()=>setAnswer('Travel')} style={{font: 'inherit'}} className={styles.LanguageCard}>
                    <div >
                        <Image src={Travel} width={70}rounded   />
                    </div>
                    <div >Travel</div>
                </button>


                <button onClick={()=>setAnswer('Friends-Family')} style={{font: 'inherit'}} className={styles.LanguageCard}>
                    <div >
                        <Image src={Friends} width={70}rounded   />
                    </div>
                    <div >Friends/Family</div>
                </button>


                <button onClick={()=>setAnswer('Other')} style={{font: 'inherit'}} className={styles.LanguageCard}>
                    <div >
                        <Image src={Other} width={70}rounded   />
                    </div>
                    <div >Other</div>
                </button>
            </div>
        </div>
    )
})

export default Interest
