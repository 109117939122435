import React, { Component } from 'react';
import {Button, Row, Image, Col} from 'react-bootstrap'
import PropTypes from 'prop-types';
import styles from './styles.css';
import axios from 'axios';
import Success from './success.png'


const CorrectFooter = props => (



      <Row  style={{ margin:'auto',width:'100vw',height:'20vh',backgroundColor:'#d7ffb8', marginBottom:'0'}}>
<Col style={{marginLeft:'2vw', marginTop:'2vh'}}>
      <Row>
<Image src={Success} style = {{width:'100px', height:'100px'}}/>
</Row>

{/* <div style={{fontWeight:'bold', color:'#58A700', fontSize:'30px', }}>
Great Job!
</div> */}
</Col>
<Col className="ml-auto">
          <Button onClick={props.next} style={{width:'16vw',marginTop:'4vh', marginLeft:'30vw', marginRight:'2vw', backgroundColor:'#58CC02', border:'none',borderRadius:'30px'}} >
    Next
          </Button>
          </Col>

    </Row>
		);



export default React.memo(CorrectFooter);

/*
<Row  style={{ margin:'auto',width:'100vw',height:'20vh',backgroundColor:'#d7ffb8', marginBottom:'0'}}>
<Col style={{marginLeft:'2vw', marginTop:'2vh'}}>
      <Row>
<Image src={Success} style = {{width:'100px', height:'100px'}}/>
</Row>
</Col>
<Col className="ml-auto">
          <Button onClick={props.next} style={{width:'16vw',marginTop:'4vh', marginLeft:'30vw', marginRight:'2vw', backgroundColor:'#58CC02', border:'none',borderRadius:'30px'}} >
    Next
          </Button>
          </Col>
    </Row>
*/
