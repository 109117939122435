import React, { Component } from 'react';
import {Row, Col, Container} from 'react-bootstrap'
import PropTypes from 'prop-types';
import styles from './WriteWordFragment.module.css';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import axios from 'axios';

// Added in Yoruba, Igbo, Twi special characters. TODO: API call handling in the future.
// const layout = {
// 	'default': [
// 		'{shift} ẹ ɛ ɔ ŋ ʒ ị n̄ ọ ụ {bksp}'

// 	],
// 	'shift': [
// 		'{shift} Ẹ Ɛ Ɔ Ŋ Ʒ Ị N̄ Ọ Ụ {bksp}'
// 	]
// }

class WriteWordFragment extends Component {
	state = {
		inputValue: '',
		layoutName: "default",
		language: null,
		layout: null
	};

	loadKeyboard = () => {
		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api/"

		axios.defaults.xsrfCookieName = 'csrftoken';
		axios.defaults.xsrfHeaderName = 'X-CSRFToken';

		axios.defaults.headers = {
			'Content-Type': 'application/json',
			Authorization: 'Token ' + this.props.token,
		};

		axios.get(url + 'course/language/' + this.props.courseId + '/').then(res => {
			const lang = res.data
			this.setState({language: lang})

			axios.get(url + 'characters/' + lang + '/' ).then(res => {
				const chars = res.data

				var lowerLayout = '{shift} ' + chars.join(' ') + ' {bksp}'
				var upperLayout = []

				for(var x = 0; x < chars.length; x++){
					upperLayout.push(chars[x].toUpperCase());
				}

				upperLayout = '{shift} ' + upperLayout.join(' ') + ' {bksp}'

				this.setState({layout:
					{
						'default': [
							lowerLayout

						],
						'shift': [
							upperLayout
						]
					}
				})

			}).catch(err => console.error(err))
		})
	}


	componentDidMount() {
		this.loadKeyboard();
		this.input.focus();

	}

	onChange = input => {
		this.setState({
			inputValue: input
		})
	
	}

	onKeyPress = button => {

		if (button === "{shift}" || button === "{lock}") this.handleShift();

	}

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
		  layoutName: layoutName === "default" ? "shift" : "default"
		});
	  };

	  onChangeInput = event => {
		const input = event.target.value;
		this.setState({ inputValue: input });
		this.keyboard.setInput(input);
	  }

	componentDidUpdate(prevProps, prevState) {
		if (this.props.result === 'learning' && this.props.result !== prevProps.result) {
			this.clearInput();

		}
	}

	inputChange = event => {
		this.setState({
			inputValue: event.target.value,
		});
	};

	onKeyDown = e => {
		if (e.key === 'Enter' && this.props.result === 'learning') {
			this.next();
		}
	};

	next = () => {
		if (this.props.result === 'learning') {
			this.props.userWrote(this.state.inputValue);
		}
	};

	clearInput = () => {
		this.input.focus();
		this.setState({ inputValue: '' });
	};

	render() {
		let inputClasses = styles.Input;

		if (this.props.result === 'correct') {
			inputClasses += ' ' + styles.InputCorrect;
		} else if (this.props.result === 'wrong') {
			inputClasses += ' ' + styles.InputWrong;
		}

		return (

			<div style={{width:'100vw',height:'70vh', backgroundColor:'white', overflow: 'scroll'}}>
					<div style={{    color:'black',fontSize: "35px",
						fontWeight:'bold',
              margin: "auto",
          textAlign: "center",
          width: '50%'}}>{this.props[0].description}</div>
					<div style={{margin: "auto",
			textAlign: "center"}}>
			<Container >
			<Row  style={{textAlign: 'center', margin: 'auto'}} className="justify-content-center justify-content-xs-center">
			</Row>
			</Container>
					{/* {input} */}
					<input
						ref={input => {
							this.input = input;
						}}
						style={{width:'400px'}}
						readOnly={this.props[0].result === 'learning'}
						value={this.state.inputValue}
						onChange={this.onChangeInput}
						onKeyDown={this.onKeyDown}
						className={inputClasses}
					/>
					<Keyboard

						keyboardRef = {r => (this.keyboard = r)}
						layoutName = {this.state.layoutName}
						layout = {this.state.layout}
						onChange = {this.onChange}
						onKeyPress = {this.onKeyPress} />

				</div>
				<Col style={{marginTop:'70vh'}}>
				<Row style={{marginLeft: '-40vw'}}>

				</Row>
				</Col>
			</div>
		);
	}
}

export default React.memo(WriteWordFragment);

WriteWordFragment.propTypes = {
	result: PropTypes.string.isRequired,
	pair: PropTypes.shape({
		word: PropTypes.string,
		description: PropTypes.string,
	}).isRequired,
	userWrote: PropTypes.func.isRequired,
	courseId: PropTypes.string.isRequired
};
