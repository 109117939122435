import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import styles from './Survey.module.css'
import { Row, Container, Button} from 'react-bootstrap';
import Experience from '../../components/Survey/Experience/Experience';
import Interest from '../../components/Survey/Interest/Interest';
import Overview from '../../components/Survey/Overview/Overview';

class Survey extends Component {
    constructor(props) {
        super(props);
        this.Interest = React.createRef();
        this.Experience = React.createRef();
      }

    state = {
        pos: 0,
        lang: this.props.match.params.language,
        Interest: '',
        Experience: ''
    }

    continueClick = () => {
        var temp = this.state.pos + 1
        this.setState(
            {
            pos: temp,
        }
        )        
        if (this.Interest.current){
            this.setState({Interest: this.Interest.current.getData()})
            
        }

        if (this.Experience.current){
            this.setState({Experience: this.Experience.current.getData()})
            
        }
    }

    backClick = () => {
        var temp = this.state.pos - 1
        this.setState(
            {
            pos: temp,
        }
        )
    }

    submit = () => {
        const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";

		axios.defaults.xsrfCookieName = 'csrftoken';
		axios.defaults.xsrfHeaderName = 'X-CSRFToken';

        axios.put(url + '/survey/' + this.state.lang + '/' + this.state.Experience + '/' + this.state.Interest)


    }
    

    render() {
        let content = <div></div>

        if (this.state.pos == 0){
            content = <div><Interest ref={this.Interest} language={this.props.match.params.language} />
            <Row style={{justifyContent: 'center'}}>
            <Button onClick={() => this.continueClick()}style={{marginTop:'4vh', border:'none',borderRadius:'15px',backgroundColor:'#ff6f1a', border:'none', font: 'inherit'}} >
                 Continue
             </Button>
       </Row>
       </div>
        }

        if (this.state.pos == 1){
            content = <div><Experience ref={this.Experience}  language={this.props.match.params.language} />
            <Row style={{justifyContent: 'center'}}>
            <Button onClick={() => this.continueClick()}style={{marginTop:'4vh', border:'none',borderRadius:'15px',backgroundColor:'#ff6f1a', border:'none', font: 'inherit'}} >
                 Continue
             </Button>
            </Row>
            </div>

        }   
        if (this.state.pos == 2){
            this.submit()
            content = <div><Overview  language={this.state.lang} /></div>
        }
        <div>

        </div>
		return (
			<React.Fragment>
				<Container className ={styles.Body}>               
               <div className={styles.SurveyBody}>
                   {content}
               </div>

	</Container>
			</React.Fragment>
		);
	}
}


const mapStateToProps = state => {
	return {
	};
};

const mapDispatchToProps = dispatch => {
	return {

	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Survey);
