import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container , Row, Image} from 'react-bootstrap';
import PropTypes from 'prop-types';

import styles from './Header.module.css';

const NavButton = props => {
	const active = props.isActive ? ` ${styles.ActiveButton}` : '';
	return (
		<Link to={props.to} className={styles.NavButton + active}>
			{props.children}
		</Link>
	);
};

const Header = props => {
	const url = props.url.split('/')[1];


	let buttons = (
		<React.Fragment>

		<Nav.Link style={{color: "black"}} href="/settings" className={styles.LogoutBtn}>
			 <span>Account Settings</span>
			 </Nav.Link>
			<Nav.Link style={{color: "black"}} className={styles.LogoutBtn}>

				<a style={{paddingLeft: '5px'}}onClick={() => {window.open("https://forms.gle/SPBLaMVG6gUnnDJs7", "_blank")}}>
			Report
			 	</a>

			 </Nav.Link>

			<Nav.Link style={{color: "black"}} href="/logout" className={styles.LogoutBtn}>
			<span>Log out</span>
			</Nav.Link>

		</React.Fragment>
	);


	return (
		<Navbar collapseOnSelect expand='sm' className={styles.Header} style={{background: props.color}}>
			<Container fluid="sm">
			  <Navbar.Brand className={styles.LogoWrapper} href='/Home'>

        </Navbar.Brand>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse id='responsive-navbar-nav'>
					<Nav className='ml-auto'>{props.loggedIn ? buttons: ''}</Nav>
				</Navbar.Collapse>
				</Container>
		</Navbar>
	);
};

export default React.memo(Header);

Header.propTypes = {
	url: PropTypes.string.isRequired,
	color: PropTypes.string
};
