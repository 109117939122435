const initialState ={
    userScore:0,
    trigger:0,
}


 const ScoreReducer =(state=initialState,action)=>{
     const {type,payload}=action
switch(type){

    case "Increment":
        let newScore= state.userScore+1;
        return {userScore:newScore};

   case "Decrement":
        let newScore2 = state.userScore-1;
        return {...state,userScore:newScore2}
   case "Unchanged":
             let newScore3 = state.userScore;
             //I think somehting needs to be changed here just to update the state
             let newTrigger=0;
             if(state.trigger==0){
                newTrigger =1;
             }
             return {...state,userScore:newScore3, trigger:newTrigger}
   case "Reset":
        return {...state,userScore:0}

    default:
        return {...state,userScore:state.userScore}
}
}
export default  ScoreReducer;
