import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from '.././LandingPage.module.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from 'react-router-dom';


const LanguageSlider = props => {
  //use interval hook from
  //https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const [sliderIndex, setsliderIndex] = useState(0);

      const outerClasses = classNames(
        'hero section center-content',
         'has-top-divider',
         'has-bottom-divider',
      'has-bg-color',
        'invert-color',

      );

      const innerClasses = classNames(
        'hero-inner section-inner',

      );

//Automatic slider
  useInterval(() => {
    if(sliderIndex>5){
      setsliderIndex(0);
    }
    if(sliderIndex<0){
      setsliderIndex(5);
    }
    setsliderIndex(sliderIndex+1)
  }, 5000);


const firstLangs = (
    <ul className={styles.LanguageList}>
    <Link to= {"/About/Yoruba"}>
  <li className={styles.LanguageListEl}>
  <a className={styles.TNj94}>
    <span className={styles.NigeriaFlag}></span>
    <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Yoruba</span>
    </a>
  </li>
  </Link>

<Link to= {"/About/Twi"}>
  <li className={styles.LanguageListEl}>
  <a className={styles.TNj94}>
    <span className={styles.GhanaFlag}></span>
    <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Twi</span>
    </a>
  </li>
</Link>
<Link to= {"/About/Igbo"}>
  <li className={styles.LanguageListEl}>
  <a className={styles.TNj94}>
    <span className={styles.NigeriaFlag}></span>
    <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Igbo</span>
    </a>
  </li>
</Link>
<Link to= {"/About/Swahili"}>
  <li className={styles.LanguageListEl}>
  <a className={styles.TNj94}>
    <span className={styles.KenyaFlag}></span>
    <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Swahili</span>
    </a>
  </li>
</Link>
<Link to= {"/About/Amharic"}>
  <li className={styles.LanguageListEl}>
  <a className={styles.TNj94}>
    <span className={styles.EthiopiaFlag}></span>
    <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Amharic</span>
    </a>
  </li>
</Link>
  </ul>
);
const secondLangs = (
    <ul className={styles.LanguageList}>
    <Link to= {"/About/Kinyarwanda"}>
    <li className={styles.LanguageListEl}>
    <a className={styles.TNj94}>
      <span className={styles.RwandaFlag}></span>
      <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Kinyarwanda</span>
      </a>
    </li>
    </Link>
    <Link to= {"/About/Somali"}>
    <li className={styles.LanguageListEl}>
    <a className={styles.TNj94}>
      <span className={styles.SomaliaFlag}></span>
      <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Somali</span>
      </a>
    </li>
    </Link>
    <Link to= {"/About/Hausa"}>
    <li className={styles.LanguageListEl}>
    <a className={styles.TNj94}>
      <span className={styles.NigeriaFlag}></span>
      <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Hausa</span>
      </a>
    </li>
    </Link>
    <Link to= {"/About/Oromo"}>
    <li className={styles.LanguageListEl}>
    <a className={styles.TNj94}>
      <span className={styles.EthiopiaFlag}></span>
      <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Oromo</span>
      </a>
    </li>
</Link>
    </ul>
);
const thirdLangs = (
  <ul className={styles.LanguageList}>
  <Link to= {"/About/Arabic"}>
  <li className={styles.LanguageListEl}>
  <a className={styles.TNj94}>
    <span className={styles.EgyptFlag}></span>
    <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Arabic</span>
    </a>
  </li>
  </Link>
  <Link to= {"/About/Wolof"}>
  <li className={styles.LanguageListEl}>
  <a className={styles.TNj94}>
    <span className={styles.SenegalFlag}></span>
    <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Wolof</span>
    </a>
  </li>
  </Link>
  <Link to= {"/About/Soninke"}>
  <li className={styles.LanguageListEl}>
  <a className={styles.TNj94}>
    <span className={styles.GuineaFlag}></span>
    <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Soninke</span>
    </a>
  </li>
  </Link>
  <Link to= {"/About/Mandinka"}>
  <li className={styles.LanguageListEl}>
  <a className={styles.TNj94}>
    <span className={styles.GuineaFlag}></span>
    <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Mandinka</span>
    </a>
  </li>
</Link>
  </ul>);
const fourthLangs = (
   <ul className={styles.LanguageList}>
   <Link to= {"/About/Mooré"}>
   <li className={styles.LanguageListEl}>
   <a className={styles.TNj94}>
     <span className={styles.BurkinaFasoFlag}></span>
     <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Mooré</span>
     </a>
   </li>
   </Link>
   <Link to= {"/About/Lingala"}>
   <li className={styles.LanguageListEl}>
   <a className={styles.TNj94}>
     <span className={styles.CongoFlag}></span>
     <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Lingala</span>
     </a>
   </li>
   </Link>
   <Link to= {"/About/Bambara"}>
   <li className={styles.LanguageListEl}>
   <a className={styles.TNj94}>
     <span className={styles.MaliFlag}></span>
     <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Bambara</span>
     </a>
   </li>
   </Link>
   <Link to= {"/About/Zulu"}>
   <li className={styles.LanguageListEl}>
   <a className={styles.TNj94}>
     <span className={styles.SouthAfricaFlag}></span>
     <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Zulu</span>
     </a>
   </li>
   </Link>
  </ul>);
  const fifthLangs = (
     <ul className={styles.LanguageList}>
     <Link to= {"/About/Coptic"}>
     <li className={styles.LanguageListEl}>
     <a className={styles.TNj94}>
       <span className={styles.EgyptFlag}></span>
       <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Coptic</span>
       </a>
     </li>
     </Link>

   <Link to= {"/About/Haitian Creole"}>
     <li className={styles.LanguageListEl}>
     <a className={styles.TNj94}>
       <span className={styles.HaitiFlag}></span>
       <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Creole</span>
       </a>
     </li>
   </Link>
     <Link to= {"/About/Shona"}>
     <li className={styles.LanguageListEl}>
     <a className={styles.TNj94}>
       <span className={styles.ZimbabweFlag}></span>
       <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Shona</span>
       </a>
     </li>
     </Link>
     <Link to= {"/About/Xhosa"}>
     <li className={styles.LanguageListEl}>
     <a className={styles.TNj94}>
       <span className={styles.SouthAfricaFlag}></span>
       <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Xhosa</span>
       </a>
     </li>
</Link>
    </ul>);
    const sixthLangs = (
       <ul className={styles.LanguageList}>
       <Link to= {"/About/Chichewa"}>
       <li className={styles.LanguageListEl}>
       <a className={styles.TNj94}>
         <span className={styles.MalawiFlag}></span>
         <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Chichewa</span>
         </a>
       </li>
       </Link>
       <Link to= {"/About/Dioula"}>
       <li className={styles.LanguageListEl}>
       <a className={styles.TNj94}>
         <span className={styles.BurkinaFasoFlag}></span>
         <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Dioula</span>
         </a>
       </li>
       </Link>
<Link to= {"/About/Kabyle"}>
       <li className={styles.LanguageListEl}>
       <a className={styles.TNj94}>
         <span className={styles.AlgeriaFlag}></span>
         <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Kabyle</span>
         </a>
       </li>
   </Link>
       <Link to= {"/About/Fante"}>
       <li className={styles.LanguageListEl}>
       <a className={styles.TNj94}>
         <span className={styles.GhanaFlag}></span>
         <span class="w9lql" style={{color: 'white', fontWeight: 'bold'}}>Fante</span>
         </a>
       </li>
       </Link>
      </ul>);
function sliderLangChooser(index){

  if(index>5){
    setsliderIndex(0);
  }
  if(index<0){
    setsliderIndex(5);
  }
  if(index==0){
      return firstLangs;

  }
if(index==1){
  return secondLangs;
}
if (index==2){
  return thirdLangs;
}
if(index==3){
  return fourthLangs;
}
if(index==4){
  return fifthLangs;
}
if(index==5){
  return sixthLangs;
}
//if index null for some reason
return firstLangs;
}


    return (

        <div className ={styles.LanguageContainer} >
          <div className={styles.LanguageContainer2} class='justify-content-center'>
            <nav className={styles.LanguageNav}>
              <span className={styles.LeftArrow}  onClick={() => setsliderIndex(sliderIndex - 1)}>
              <ArrowBackIosIcon/>
              </span>
              <div className={styles.LanguageContainer3} >

              {sliderLangChooser(sliderIndex)}


              </div>

              <span className={styles.RightArrow}>
              <ArrowForwardIosIcon style ={{color:"white"}}  onClick={() => setsliderIndex(sliderIndex + 1)}/>
              </span>
            </nav>

          </div>
          </div>

    );


}
export default LanguageSlider
