import React, { useState } from 'react';
import classNames from 'classnames';
import logingirl from '.././img/logingirl.png';
import styles from './styles.css'
import { Link } from 'react-router-dom';

import { Row, Container, Image, Button, Col, Card} from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';
import 'bootstrap/dist/css/bootstrap.min.css';


const Footer= props => {



      const outerClasses = classNames(
        'hero section center-content',
         'has-top-divider',
         'has-bottom-divider',
      'has-bg-color',
        'invert-color',

      );

      const innerClasses = classNames(
        'hero-inner section-inner',

      );


    return (
      <section

        style={{marginTop:100, height:500, color:"white",background: 'linear-gradient(180deg, rgba(130, 95, 182, 0.9215) 0%, rgba(245, 122, 26, 0.97) 100%)'}}
      >

        <Container>

        <Row style={{marginTop:100, color:"white"}}>
        <Col style={{marginTop:100, color:"white"}}>

    © 2021 Mandla Inc.
        </Col>
      <Col style={{marginTop:100, color:"white"}}>
      <SocialIcon url="https://www.linkedin.com/company/74584184/" />
      </Col>
      <Col style={{marginTop:100, color:"white"}}>
      <SocialIcon url="https://twitter.com/mandlaapp" />
      </Col>
      <Col style={{marginTop:100, color:"white"}}>
      <SocialIcon url="https://www.instagram.com/mandlaapp/" />
      </Col>
      <Col style={{marginTop:100, color:"white"}}>
      <SocialIcon url="https://www.facebook.com/mandlaapp/" />
      </Col>
      <Col style={{marginTop:100, color:"white"}}>
      <SocialIcon url="https://www.tiktok.com/@mandla.app?lang=en" />
      </Col>
      <Link to = "/Privacy">
      <Col style={{marginTop:100, color:"white"}}>

  Privacy Policy
      </Col>
      </Link>
            <Link to = "/Terms">
      <Col style={{marginTop:100, color:"white"}}>

Terms of Service
      </Col>
        </Link>
        </Row>
    </Container>

      </section>
    );


}
export default Footer
