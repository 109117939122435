import * as actionTypes from './actionTypes';
import axios from 'axios';
import { firebaseObj } from "../../firebase"

export const profileStart = () => {
	return {
		type: actionTypes.PROFILE_START,
	};
};

export const profileLoaded = (username, courses, languages, progress) => {
	return {
		type: actionTypes.PROFILE_LOADED,
		username: username,
		courses: courses,
		languages: languages,
		progress: progress,
	};
};


export const profileLoad = token => {
	return (dispatch, getState) => {

		dispatch(profileStart());

		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";

		axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
		axios.defaults.xsrfCookieName = 'csrftoken';

		if (!token) {
			token = getState().auth.token;
		}
		firebaseObj.auth().onAuthStateChanged(function (user) {
			if (user) {
				user.getIdToken(true).then(function (idToken) {  // <------ Check this line
					// console.info("firebaseObj shows the Firebase idToken now ", idToken); // It shows the Firebase token now
					localStorage.setItem("token", idToken)
				});
			}
		});
		token = localStorage.getItem("token");

		axios.defaults.headers = {
			'Content-Type': 'application/json',
			Authorization: 'Token ' + token,
		};

		const username = localStorage.getItem('username');
		axios.get(url + '/profile/u/').then(async res => {
			if (res.data) {
				const obj = res.data;

				let progress = {};
				if (obj.progress !== '') {
					try {
						progress = obj.progress;
					} catch (e) {
						progress = {};
					}
				}
				const profileLanguagesF = []
				const profileCourses = []
			if(progress){
				if (progress.progressItems.size === 0){
				}

				else{

//Getting the user's languages
for (const key in progress.progressItems) {
	let course = progress.progressItems[key];
	let res3 = await axios.get(url + '/language/' + key)

	const profileLang = {
		name: res3.data.name,
		flag: res3.data.flag,
		id: res3.data.transId,
		availWeb:res3.data.availWeb,
	}

if(profileLang.availWeb){
	profileLanguagesF.push(profileLang);
}
	//Courses submap Map
for (const key2 in course) {
	// let courseVar= course[key2];
	  // console.log('The value for '+ key + ' ' + key2 + ' is = ' + courseVar);
		//Submap of courses submap (an individual course) key3 should be the course ID
		for (const courseID in course[key2]) {
			if(course[key2].size!=0){
			let part= course[key2][courseID].part;
			let completed=course[key2][courseID].completed;
			  // console.log('The value for '+ key + ' ' + key2 +' is = ' + courseID);
						// let res3 = await axios.get(url + '/course/' + courseID);
				const profileCourse = {
					id: courseID,
					part:part,
					completed:completed,
				}

				profileCourses.push(profileCourse);
		}
	}

}
}

				}

}
else{
	let progressItemsMap = new Map();
	progress = {
		currentLanguage:null,
		progressItems: progressItemsMap,
	};
}

				// console.log(profileCourses);

				dispatch(profileLoaded(username, profileCourses, profileLanguagesF, progress));
			}
		});
	};
};
