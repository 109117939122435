import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';
import StudyTheWord from '../StudyTheWord/StudyTheWord';
import StudyTheWordPhrases from '../StudyTheWordPhrases/StudyTheWordPhrases';
import MultipleChoice from '../MultipleChoice/MultipleChoice';
import MultipleChoiceWithPictures from '../MultipleChoiceWithPictures/MultipleChoiceWithPictures';
import TranslateSentence from '../TranslateSentence/TranslateSentence';
import TranslateSentenceFromAudio from '../TranslateSentenceFromAudio/TranslateSentenceFromAudio';
import TranslateSentenceReverse from '../TranslateSentenceReverse/TranslateSentenceReverse';
import Introduction from '../Introduction/Introduction';
import Image2Words from '../Image2Words/Image2Words'
import Words2Image from '../Words2Image/Words2Image'
import WriteWordFragment from '../WriteWordFragment/WriteWordFragment';



let content;

function chooseGame(numTurns, props, isIntro) {
	console.log({...props});
	if(isIntro){
		return <Introduction {...props}/>
	}
	//The following logic works on the assumption that TOTAL_TURNS is 30. Will need modification if that is chaged.

if(numTurns >25){

		if(props[0].image){
	content = <StudyTheWord {...props} />
	//send a signal that the game type is StudyTheWord
}
else{
		content = <StudyTheWordPhrases {...props} />
}
}


else {

console.log(props[0]);
console.log(props[1]);
console.log(props[2]);

	const alphabet = "ABC";



	let randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)];
	
		// content = <TranslateSentenceReverse{...props}/>
		// content = <TranslateSentenceFromAudioReverse{...props}/>
		if(numTurns%2==0){

			content = <TranslateSentence{...props}/>
		};

if(numTurns%2==1){
	content = <TranslateSentenceFromAudio {...props}  />
};
//If C just stays TranslateSentenceReverse



	//Words with Images
	if(props[0].image){
		const alphabet2 = "ABCD";


		let randomCharacter = alphabet2[Math.floor(Math.random() * alphabet.length)];

						content = <MultipleChoice{...props}/>
			if(randomCharacter==="A"){

				content = <MultipleChoice{...props}/>
			};
	if(randomCharacter==="B"){
		content = <MultipleChoiceWithPictures {...props}  />
	};
	if(randomCharacter==="C"){
		content = <Image2Words {...props}  />
	};
	if(randomCharacter==="D"){
		content = <Words2Image {...props}  />
	};
}



}

	return content;
}
const GameBottomHalf = props => (

	<div className={styles.Content}>
	{chooseGame(props.turns, props, props.isIntro)}



	</div>

);

export default React.memo(GameBottomHalf);

GameBottomHalf.propTypes = {
	next: PropTypes.func.isRequired,
	courseId: PropTypes.string
};
