
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/auth';
import styles from './Admin.module.css';
import Header from '../../components/Header/Header';
import MetaTags from 'react-meta-tags'


import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";


const config = {
	apiKey: "AIzaSyDtMEXlQAmUFMyJaO9kTDarGPiM7rn2pQY",
	authDomain: "mandla-demo.firebaseapp.com",
	projectId: "mandla-demo",
	storageBucket: "mandla-demo.appspot.com",
	messagingSenderId: "1069863514519",
	appId: "1:1069863514519:web:d7955722fefb9c39ca94d2",
	measurementId: "G-7WKCEGY82Z"
};


class Admin extends Component {
	state = {
		email: '',
		password: '',
		emailInputClasses: styles.Input,
		passwordInputClasses: styles.Input,
		errorMessage: null,
	};

	loginClick = () => {
		let correct = true;

		if (!this.state.email.includes("mandla.app")){
			correct = false;
			this.setState({
				emailInputClasses: styles.Input + ' ' + styles.Error,
			});
		}
		if (this.state.email === '') {
			correct = false;
			this.setState({
				emailInputClasses: styles.Input + ' ' + styles.Error,
			});
		}
		if (this.state.password === '') {
			correct = false;
			this.setState({
				passwordInputClasses: styles.Input + ' ' + styles.Error,
			});
		}
		if (correct) {
			this.login();
		}
	};

	login = () => {
		this.setState({ errorMessage: null });
		const user = {
			email: this.state.email,
			password: this.state.password
		};
		this.props.onAuth(this.state.email, this.state.password);
	};

	googleLogin = () => {

		// const config = {
		// 	apiKey: "AIzaSyDtMEXlQAmUFMyJaO9kTDarGPiM7rn2pQY",
		// 	authDomain: "mandla-demo.firebaseapp.com",
		// 	projectId: "mandla-demo",
		// 	storageBucket: "mandla-demo.appspot.com",
		// 	messagingSenderId: "1069863514519",
		// 	appId: "1:1069863514519:web:d7955722fefb9c39ca94d2",
		// 	measurementId: "G-7WKCEGY82Z"
		// };

		const provider = new firebase.auth.GoogleAuthProvider()

		// firebase.initializeApp(config);

		const auth = firebase.auth();

		auth.signInWithPopup(provider).then(() => {
			var user = auth.currentUser;
			const expirationDate = new Date(new Date().getTime() + 24 * 3600 * 1000);

			if (user == null) return;

			user.getIdToken(true).then((tkn) => {
				this.props.onGoogle(tkn);
			})


		})

	}

	resetPassword = () => {

		var email = this.state.email
		const auth = firebase.auth()

		auth.sendPasswordResetEmail(email).then(() => {
			alert("Check your email for the reset email.")
		}).catch((err) => {
			if (err.code === 'auth/invalid-email'){
				alert("Please enter your email into the email field.")
			}
			else{
				console.error(err)
			}
		}
		)


	}

	onKeyDown = event => {
		if (event.key === 'Enter') {
			this.loginClick();
		}
	};

	inputChange = event => {
		const name = event.target.name;
		if (this.state[name + 'InputClasses'] === styles.Input + ' ' + styles.Error) {
			this.setState({
				[name]: event.target.value,
				[name + 'InputClasses']: styles.Input,
			});
		} else {
			this.setState({ [name]: event.target.value });
		}
	};

	render() {
		let errorMessage = this.state.errorMessage;

		if (this.props.error) {
			errorMessage = (
				<div className={styles.ErrorMessage}>
					Oopsie, wrong username or password. Let's try again. Careful with the Caps Lock!
				</div>
			);
		}

		return (
			<React.Fragment>
				<MetaTags>
					 <title>Learn Languages with Sebmita</title>
					 <meta id="meta-description" name="description" content="Learn Languages with Sebmita" />
					 <meta id="og-title" property="og:title" content="Learn Languages with Sebmita" />
					 <meta name="apple-itunes-app" content="app-id=1567445203, app-argument=myURL" /> 
				 </MetaTags>
				<Header url={this.props.match.url} />
				<div className={styles.Content}>
					<div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
					color:"black",
					marginTop:"10vh",
        }}></div>
					<div className={styles.WhiteBox}>
						<div className={styles.LoginTitle}>Login</div>
						{errorMessage}
						<div className={styles.Label}>Email:</div>
						<input
							name="email"
							onChange={this.inputChange}
							className={this.state.emailInputClasses}
						/>
						<div className={styles.Label}>Password:</div>
						<input
							name="password"
							onChange={this.inputChange}
							type="password"
							className={this.state.passwordInputClasses}
							onKeyDown={this.onKeyDown}
						/>
						<p className={styles.ForgotPassword}>
							<a onClick={this.resetPassword}>Forgot password?</a>
							</p>
						<div onClick={this.loginClick} className={styles.LoginButton}>
							Login
						</div>

					</div>


				</div>

			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.auth.loading,
		error: state.auth.loginError,
	};
};

const mapDispatchToPropos = dispatch => {
	return {
		onAuth: (email, password) => dispatch(actions.authLogin(email, password)),
		onGoogle: (idToken) => dispatch(actions.authGoogle(idToken))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropos,
)(Admin);
