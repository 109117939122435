import React from 'react';
import {Button, Row, Col} from 'react-bootstrap'
import styles from './NeutralFooter.module.css';
import { connect } from 'react-redux';

const NeutralFooter = (props,{selectedAnswer}) => {
return (

    //add position fixed?
      <Row  style={{ margin:'auto',width:'100vw',backgroundColor:'white', bottom: '0'}}>

    <Col className={styles.Hidden} style={{paddingBottom: '40px'}}>
      {/**<Button onClick={props.next} style={{width:'16vw',marginTop:'4vh',marginLeft:'2vw',borderColor:'#e5e5e5',borderRadius:'30px',backgroundColor:'white', color:'#afafaf'}} >
    Skip
      </Button>**/}
    </Col>
    <Col className={styles.Center}>
          <Button className={styles.ExtendButton} onClick={props.check} style={{marginTop:'4vh', border:'none',borderRadius:'30px',backgroundColor:'#ff6f1a', border:'none'}} >
    Check
          </Button>
    </Col>

    </Row>
		);
}
const mapStateToProps = state => {
	return {
		selectedAnswer: state.selectedAnswer,
	};
};


// const mapDispatchToProps=(dispatch)=>{
// 	return {checkButtonHasBeenClicked:()=>dispatch(checkButtonHasBeenClicked()), checkButtonHasNotBeenClicked:()=>dispatch(checkButtonHasNotBeenClicked())}
//
//
// }
// export default connect(mapStateToProps,
// mapDispatchToProps)(React.memo(NeutralFooter));
export default connect(mapStateToProps)(React.memo(NeutralFooter));
