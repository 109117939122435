import React from 'react';
import { connect } from 'react-redux';
import {Button,Row,Col, Image, Container} from 'react-bootstrap'
import styles from'./LangAboutComponent.module.css';
import {Link} from 'react-router-dom';
import location from './location.png';
import people from './people.png';




const LangAboutComponent = props => (
	// <Container className={styles.ViewController}>
<div className={styles.ViewBox}>
<Row>
<Container style={{marginBottom: '20px'}}>

<Row className='justify-content-center' id={0} style={{color:'black',fontWeight:'bold',fontSize:'70px', marginTop: '60px'}}>
<Col  sm={'auto'} xs={12} className={styles.FlagCol}><Image className={styles.Flag} src = {props.flag}/></Col>
<Col className={styles.LangData}>
	<Row><Col><Row  className={styles.NameRow} ><span className={styles.Name}>{props.name}</span></Row>
				<Row style={{marginBottom: '10px'}} className={styles.SecondRow}><Col className={styles.SecondCol}><div className={styles.IconText1}>{props.region}</div><Image  src={location} width='20px' height='25px' /></Col></Row>
				<Row  className={styles.ThirdRow}><Col className={styles.ThirdCol}><div className={styles.IconText2}>{props.learnerCount} learners</div><Image src={people} width='25px' height='25px' /></Col></Row>
				{/* style={{paddingRight: '50%', marginBottom: '10px'}} */}
				{/* style={{paddingRight: '50%'}} */}
				{/* style={{width: '100%'}}  */}
	</Col></Row>
	
	</Col>
</Row>
<hr className={styles.hr}/>
</Container>
</Row>
{/* <Row>
<div style={{fontWeight:"bold", fontSize:"30px"}}> Quick Facts </div>
</Row>
<Row>

<div style={{fontWeight:"bold"}}>Region: 
</div>
<div>  {props.region}
</div>

</Row>
<Row>
<div style={{fontWeight:"bold"}}> Learners: </div>
<div>
  {props.learnerCount}
</div>
</Row> */}
<div>
<Row>
<br></br>
<div style={{fontWeight:"bold", fontSize:"30px", color:'black'}}> About the {props.name} Language </div>
</Row>
<Row>
	
<div style ={{fontSize:'20px', color: 'black'}}>
	<br/>
{props.about}
</div>
</Row>
</div>
</div>
// </Container>



);
const mapStateToProps = state => {
	return {
		profile: state.profile,
		token: state.auth.token,
		newUser: state.newUser.newUser
	};
};

const mapDispatchToProps = dispatch => {
	return {

	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LangAboutComponent);
