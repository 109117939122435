import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Image, Col} from 'react-bootstrap'
import styles from './Words2Image.module.css';
import {correctAnswerSelected, incorrectAnswerSelected} from '../../.././store/actions/AnswerCheckActions'
import {connect }from 'react-redux'

function isPlaying(audio) { return !audio.paused; }

function stopPlaying(audio){

		if (isPlaying(audio)){
			audio.pause()
			audio.currentTime = 0
		}
		else{}
	}

document.addEventListener('click', event => {
		if (event.target.matches('button')) {
		  event.target.focus()
		}
	  })

const Words2Image = (props) => {
	const [button0styles, setbutton0styles] = useState("")
	const [button1styles, setbutton1styles] = useState("")
	const [button2styles, setbutton2styles] = useState("")

	const [selected, setSelected] = useState(3)

	const [selectedColor0, setSelectedColor0] = useState(styles.UnselectedColor)
	const [selectedColor1, setSelectedColor1] = useState(styles.UnselectedColor)
	const [selectedColor2, setSelectedColor2] = useState(styles.UnselectedColor)

	const[disabled, setDisabled] = useState(false)


	useEffect(() => {
		setDisabled(false)
		setSelectedColor0(styles.UnselectedColor)
		setSelectedColor1(styles.UnselectedColor)
		setSelectedColor2(styles.UnselectedColor)
		if (props.checkButtonClicked){
			setSelected(props.order.indexOf(0))
			if (selected==0){
				setbutton0styles(styles.CorrectAnswer)
				setSelectedColor0('')
				setbutton1styles('')
				setbutton2styles('')


			}

			if (selected==1){
				setbutton1styles(styles.CorrectAnswer)
				setSelectedColor1('')
				setbutton0styles('')
				setbutton2styles('')

			}

			if (selected==2){
				setbutton2styles(styles.CorrectAnswer)
				setSelectedColor2('')
				setbutton0styles('')
				setbutton1styles('')

			}

			if (selected==3){}
			setDisabled(true)

		}
		else{
			setbutton0styles('')
			setbutton1styles('')
			setbutton2styles('')
			setDisabled(false)
		}
	})


	const order = props.order
	const audio0 = new Audio(props[order[0]].audio)
	const audio1 = new Audio(props[order[1]].audio)
	const audio2 = new Audio(props[order[2]].audio)

	let correctAnswerChoice=props[0].word;
		// const dispatch =useDispatch()


		const select=(selection)=>{
			if (selection === props[order[0]].word){
				stopPlaying(audio0)
				stopPlaying(audio1)
				stopPlaying(audio2)
				audio0.play()
			}
			if (selection === props[order[1]].word){
				stopPlaying(audio0)
				stopPlaying(audio1)
				stopPlaying(audio2)
				audio1.play()
			}

			if (selection === props[order[2]].word){
					stopPlaying(audio0)
					stopPlaying(audio1)
					stopPlaying(audio2)
					audio2.play()
			}
			if(selection===correctAnswerChoice){
				//dispatches
				props.correctAnswerSelected()

	}
			else{
				//dispatches
				props.incorrectAnswerSelected()

			}

		}
return (
	<div style={{width:'100vw',height:'70vh', backgroundColor:'white', overflow: 'scroll'}}>
		<div className={styles.Main}>

		<div style={{    fontSize: "15vw",
		lineHeight: "24px",
				margin: "0",
		textAlign: "left",
		width: '100%'}}>
		<div style={{    color:'black',fontSize: "35px",

				margin: "auto",
		textAlign: "center", lineHeight: '1.15',
		width: '100%'}}> Which one of these Words matches the Image below </div>
				</div>
				<div style={{width:'150px', margin:'auto', marginTop:'10vh'}}>
				<Image src={props[0].image} style={{ width:'170px', height:'140px', borderRadius:'10px'}}/>


				</div>
				<div style={{    color:'black', fontWeight:'bold', marginTop:'20px',fontSize: "20px",

						margin: "auto",
				textAlign: "center", lineHeight: '1.15',
				width: '100%'}}> 	{props[0].description} </div>

				<Col style={{marginTop:'10vh'}}>
				<Row style={{marginBottom:'2vh'}}>
				<Button disabled={disabled} onClick ={()=>select(props[order[0]].word, props[order[0]].audio)} style={{backgroundColor: 'none', marginBottom:'5vh',  margin: 'auto',border:'none'}} className={button0styles+ ' ' + selectedColor0 + ' ' + styles.Button} >
				{props[order[0]].word}
				</Button>
				</Row>

				<Row style={{marginBottom:'2vh'}} >
				<Button  disabled={disabled} onClick ={()=>select(props[order[1]].word,props[order[1]].audio)} style={{backgroundColor: 'none',marginBottom:'5vh', margin: 'auto',border:'none'}} className={button1styles + ' ' + selectedColor1 + ' ' + styles.Button} >
				{props[order[1]].word}
				</Button>
				</Row>
				<Row style={{marginBottom:'2vh'}}>
				<Button disabled={disabled} onClick ={()=>select(props[order[2]].word,props[order[2]].audio)} style={{backgroundColor: 'none',marginBottom:'5vh', margin: 'auto',border:'none'}} className={button2styles+ ' ' + selectedColor2 + ' ' + styles.Button} >
				{props[order[2]].word}
				</Button>
				</Row>
				<Row style={{marginBottom:'2vh'}}>

				</Row>
				</Col>



		</div>




	</div>

);
}
const mapStateToProps = state => {
	return{
		checkButtonClicked: state.checkButtonHasBeenClicked.checkButtonClicked
	}
}
const mapDispatchToProps=(dispatch)=>{
	return {correctAnswerSelected:()=>dispatch(correctAnswerSelected()), incorrectAnswerSelected:()=>dispatch(incorrectAnswerSelected())}


}
export default connect(mapStateToProps,mapDispatchToProps)(React.memo(Words2Image));


Words2Image.propTypes = {
	word: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	next: PropTypes.func.isRequired,
};
