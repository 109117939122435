import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Home from './containers/Home/Home';
import LangAbout from './containers/Home/LangAbout';
import Login from './containers/Login/Login';
import Share from './containers/Share/Share';
import Settings from './containers/Settings/Settings';
import Privacy from './containers/Privacy/Privacy';
import Terms from './containers/Terms/Terms';
import LandingPage from './containers/LandingPage/LandingPage';
import Logout from './containers/Logout/Logout';
import SignUp from './containers/SignUp/SignUp';
import Learn from './containers/Learn/Learn';
 import Courses from './containers/Courses/Courses';
import Admin from './containers/Admin/Admin';
import * as authActions from './store/actions/auth';
import * as profileActions from './store/actions/profile';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Survey from './containers/Survey/Survey';
import Languages from './containers/Languages/Languages';
import { firebaseObj } from "./firebase"
import Default from './Home/Home';
import JoinMailingList from './containers/JoinMailingList/JoinMailingList';
import {android, ios} from 'platform-detect'
// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";

// const config = {
// 	apiKey: "AIzaSyDtMEXlQAmUFMyJaO9kTDarGPiM7rn2pQY",
// 	authDomain: "mandla-demo.firebaseapp.com",
// 	projectId: "mandla-demo",
// 	storageBucket: "mandla-demo.appspot.com",
// 	messagingSenderId: "1069863514519",
// 	appId: "1:1069863514519:web:d7955722fefb9c39ca94d2",
// 	measurementId: "G-7WKCEGY82Z"
// };

// firebase.initializeApp(config);
class App extends Component {
	state = {
		Courses: null,
		Learn: null,
		Course: null,
		CreateCourse: null,
		EditCourse: null,
		Languages: null,
		Admin: null,
		NotFound: null,
		timeLoggedIn: 0,

	};

	componentDidMount() {
			//Check if android or ios
        //if ios
        if(ios){
            window.location.replace("https://apps.apple.com/us/app/mandla/id1567445203");
        }
        //if android
        else if(android){
            window.location.replace("https://play.google.com/store/apps/details?id=com.sebmita.sebmita");
        }
       
		
		document.title = 'Learn Languages with Sebmita';
		this.props.onTryAutoSignup();
		firebaseObj.auth().onAuthStateChanged((user) => {
			if (user) {
				user.getIdToken(true).then((idToken)  => {  // <------ Check this line
					// console.info("firebaseObj shows the Firebase idToken now ", idToken); // It shows the Firebase token now
					localStorage.setItem("token", idToken)
				});
			}
			else {
				if (!window.location.pathname.includes("share")&!window.location.pathname.includes("Share")&window.location.pathname!="/login"&& (!window.location.pathname.includes("newUser") &&!window.location.pathname.includes("About")&&!window.location.pathname.includes("about")&&!window.location.pathname.includes("terms")&&!window.location.pathname.includes("privacy")) &&window.location.pathname!="/" && window.location.pathname!="/join" && window.location.pathname!="/mail") {
 					window.location.href = "/login";
 				}
			}
		});


	}

	componentDidUpdate(prevProps) {
		if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
			this.props.profileLoad(this.props.token);
			const path = this.props.location.pathname;
			if (path === '/Home' || path === '/login' || path === '/register') {
				setTimeout(this.loadComponents, 500);
			} else {
				this.loadComponents();
			}
		}
	}


	loadComponents = () => {
		const components = ['Courses', 'Learn', 'Course', 'CreateCourse', 'EditCourse', 'Languages', 'NotFound'];
		for (let c of components) {
			import('./containers/' + c + '/' + c).then(component =>
				this.setState({ [c]: component.default }),
			);
		}
	};

	render() {
		// console.log('load =>')
    	const { isAuthenticated, loading } = this.props;
    // console.log(isAuthenticated);

    // console.log(isAuthenticated + "isAuthenticated");
    // console.log(this.props +"this.props");
		if (isAuthenticated) {
			const { Courses, Learn, Course, CreateCourse, EditCourse, Languages, NotFound } = this.state;
			return (
				<ErrorBoundary>
					<Switch>
						<Route path="/logout" exact component={Logout} />
						<Route path="/share" exact component={Share} />
						<Route path="/courses/logout" exact component={Logout} />
						<Route path="/course/create" exact component={CreateCourse} />
						<Route path="/course/:courseId/edit" exact component={EditCourse} />
						<Route path="/course/:courseId" exact component={Course} />
						<Route path="/courses" exact component={Courses} />
						<Route path="/courses/:language" exact component={Courses} />
						<Route path="/languages" exact component={Languages} />
						<Route path="/settings" exact component={Settings} />
						<Route path="/learn/:courseId/:part" exact component={Learn} />
						<Route path="/Home" exact component={Home} />
						<Route path="/mail" exact component={JoinMailingList} />
            <Route path="/Default" exact component={Default} />
						<Route path="/Privacy" exact component={Privacy} />
						<Route path="/Terms" exact component={Terms} />
            <Route path="/About/:language" exact component={LangAbout} />
						<Route path="/newUser/learn/:courseId/:part" exact component={Learn} />
		 				<Route path="/newUser/languages" exact component={Languages} />
		 				<Route path="/newUser/courses/:language" exact component={Courses} />
		 				<Route path="/newUser/:language/Survey" exact component={Survey} />
            <Route path="/login" exact component={Login} />
						<Redirect path="/" exact to="/Default" />
						<Redirect path="/join" exact to="/Home" />

						<Route path="*" component={NotFound} />
					</Switch>
				</ErrorBoundary>
			);
		}
		return (
			<Switch>
				<Route path="/" exact component={LandingPage} />
				<Route path="/share" exact component={Share} />
				<Route path="/courses/logout" exact component={Logout} />
				<Route path="/newUser/learn/:courseId/:part" exact component={Learn} />
				<Route path="/newUser/languages" exact component={Languages} />
				<Route path="/newUser/courses/:language" exact component={Courses} />
				<Route path="/newUser/:language/Survey" exact component={Survey} />
        <Route path="/About/:language" exact component={LangAbout} />
				<Route path="/mail" exact component={JoinMailingList} />
				<Route path="/Privacy" exact component={Privacy} />
				<Route path="/Terms" exact component={Terms} />
				<Route path="/admin" exact component={Admin} />
				<Route path="/join" exact component={SignUp} />
				<Route path="/login" exact component={Login} />
        		<Route path="/home" exact component={Login} />
				{!loading && <Redirect path="*" to="/" />}
			</Switch>
		);
	}
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.token !== null,
	token: state.auth.token,
	loading: state.auth.loading,
});

const mapDispatchToProps = dispatch => ({
	onTryAutoSignup: () => dispatch(authActions.authCheckState()),
	profileLoad: token => dispatch(profileActions.profileLoad(token)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(App),
);

App.propTypes = {
	isAuthenticated: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	onTryAutoSignup: PropTypes.func.isRequired,
	token: PropTypes.string,
};

App.defaultProps = {
	token: '',
};
