import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Welcome.module.css';

const Welcome = () => (
	<div className={styles.WelcomeCard}>
		<div className={styles.TextWrapper}>
			<div className={styles.Title}> Welcome to Sebmita! </div>
		Looks like you aren't learning any languages right now. Click the plus sign below to add a new language.
		</div>
		<div className={styles.BtnWrapper}>

		</div>
	</div>
);

export default React.memo(Welcome);
