import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/auth';

import styles from './SignUp.module.css';
import Header from './Header/Header';
import { Link } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database"
import { CountryDropdown} from 'react-country-region-selector';
import {Helmet} from 'react-helmet'
import dayjs from 'dayjs';



const config = {
	apiKey: "AIzaSyDtMEXlQAmUFMyJaO9kTDarGPiM7rn2pQY",
	authDomain: "mandla-demo.firebaseapp.com",
	projectId: "mandla-demo",
	storageBucket: "mandla-demo.appspot.com",
	messagingSenderId: "1069863514519",
	appId: "1:1069863514519:web:d7955722fefb9c39ca94d2",
	measurementId: "G-7WKCEGY82Z"
};

var db = firebase.database()

// firebase.initializeApp(config);

class SignUp extends Component {
	state = {
		userName: '',
		email: '',
		password: '',
		password2: '',
		userNameInputClasses: styles.Input,
		nameInputClasses: styles.Input,
		emailInputClasses: styles.Input,
		passwordInputClasses: styles.Input,
		password2InputClasses: styles.Input,
		errorMessage: null,
		isSubscribe: true,
		location: '',
		startingDate: dayjs().format('MMMM D YYYY' ),
		progress: {progressItems:null, currentLanguage:null},
	};

	validateEmail(elementValue) {
		var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		return emailPattern.test(elementValue);
	}

	selectCountry (val) {
		this.setState({ country: val });
	  }

	  subscribeClick = () => {
		var email = this.state.email
        var mail = db.ref('Emails')

        mail.orderByChild("email").equalTo(email)
        .once("value", snapshot =>{
            if (snapshot.exists()){
				//user already subscribed
			}
            else{
                var mailRef = db.ref('Emails').push()
                mailRef.set({
                    'email': email
                })
                var countRef = db.ref('NumCurrentSubs/currentCount')
		        countRef.transaction(function (current_value) {
			    return (current_value || 0) + 1;
		})
            }
        })
	}

	loginClick = () => {
		let correct = true;

		if (this.state.userName === '') {
			correct = false;
			this.setState({
				userNameInputClasses: styles.Input + ' ' + styles.Error,
			});
		}
		if (!this.validateEmail(this.state.email)) {
			correct = false;
			this.setState({
				emailInputClasses: styles.Input + ' ' + styles.Error,
			});
		}
		if (this.state.password === '') {
			correct = false;
			this.setState({
				passwordInputClasses: styles.Input + ' ' + styles.Error,
			});
		}
		if (this.state.password2 !== this.state.password) {
			correct = false;
			const errorMessage = <div className={styles.ErrorMessage}>Passwords don't match</div>;

			this.setState({
				password2InputClasses: styles.Input + ' ' + styles.Error,
				errorMessage: errorMessage,
			});
		}

		if (correct) {
			this.signUp();
			if (this.state.isSubscribe === true){
				this.subscribeClick();
			}
		}
	};

	signUp = () => {
		this.setState({ errorMessage: null });
		this.props.onAuth(this.state.userName, this.state.email, this.state.password, this.state.firstName, this.state.lastName, this.state.location, this.state.startingDate, this.state.progress);

	};

	onKeyDown = event => {
		if (event.key === 'Enter') {
			this.loginClick();
		}
	};

	handleCheck = (event) => {
		const target = event.target;
		const value = this.state.isSubscribe === true ? false: true;
		const name = target.name;

		this.setState({
		  [name]: value
		});
	  }

	inputChange = event => {
		const name = event.target.name;
		if (this.state[name + 'InputClasses'] === styles.Input + ' ' + styles.Error) {
			this.setState({
				[name]: event.target.value,
				[name + 'InputClasses']: styles.Input,
				errorMessage: null,
			});
		} else {
			this.setState({ [name]: event.target.value, errorMessage: null });
		}
	};

	render() {
		let errorMessage = this.state.errorMessage;

		if (this.props.error) {
			let response = 'Error';
			if (
				this.props.error.response &&
				this.props.error.response.request &&
				this.props.error.response.request.response
			) {
				response = this.props.error.response.request.response;
			}
			const p = JSON.parse(response);
			let error = '';
			for (var key in p) {
				if (p.hasOwnProperty(key)) {
					error += ' ' + p[key];
				}
			}

			errorMessage = <div className={styles.ErrorMessage}>{error}</div>;
		}

		return (
			<React.Fragment>
				<Helmet>
				<meta name="apple-itunes-app" content="app-id=1567445203, app-argument=myURL" /> 
				</Helmet>
				<Header url={this.props.match.url} />
				<div className={styles.Content}>
					<div className={styles.WhiteBox}>
						<div className={styles.LoginTitle}>Create your Sebmita Account</div>
						{errorMessage}
						<div className={styles.Label}>Email:</div>
						<input
							name="email"
							onChange={this.inputChange}
							className={this.state.emailInputClasses}
						/>
						<div className={styles.Label}>Username:</div>
						<input
							name="userName"
							onChange={this.inputChange}
							className={this.state.userNameInputClasses}
						/>
						<div className={styles.Label}>First Name:</div>
						<input
							name="firstName"
							onChange={this.inputChange}
							className={this.state.nameInputClasses}
						/>
						<div className={styles.Label}>Last Name:</div>
						<input
							name="lastName"
							onChange={this.inputChange}
							className={this.state.nameInputClasses}
						/>
						<div className={styles.Label}>Password:</div>
						<input
							name="password"
							onChange={this.inputChange}
							type="password"
							className={this.state.passwordInputClasses}
						/>
						<div className={styles.Label}>Verify Password:</div>
						<input
							name="password2"
							onChange={this.inputChange}
							type="password"
							className={this.state.password2InputClasses}
							onKeyDown={this.onKeyDown}
						/>
						<div className={styles.Label}>Country:</div>
						<div >
						<CountryDropdown
          					value={this.state.country}
          					onChange={(val) => this.selectCountry(val)}
							classes={this.state.nameInputClasses}
							defaultOptionLabel = ""/>
						</div>
						<div className={styles.Label}>Subscribe to Sebmita's mailing list for exciting new updates</div>
						<input
						name="isSubscribe"
						type="checkbox"
						checked={this.state.isSubscribe}
						onChange={this.handleCheck} />

						<br />

						<div onClick={this.loginClick} className={styles.LoginButton}>
							Start Learning
						</div>
						<div style={{color:'black', fontSize:'15px'}}>
						<span>
						Already a Sebmita user?
						  <Link to="/login" >
						<div className={styles.LoginLink} style={{color:'black', fontWeight:'bold'}}>
						<u>	Log in </u>
						</div>
						</Link>
						</span>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.auth.loading,
		error: state.auth.signupError,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onAuth: (username, email, password, firstName, lastName, location, startingDate) => dispatch(actions.authSignup(username, email, password, firstName, lastName, location, startingDate)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SignUp);
