const initialState ={
    langAboutPopupWasOpened:false
}


 const CheckButtonReducer =(state=initialState,action)=>{
     const {type,payload}=action
switch(type){
    case "Opened":
        return {langAboutPopupWasOpened:true};

   case "Closed":
        return {...state,langAboutPopupWasOpened:false}

    default:
        return {...state,langAboutPopupWasOpened:false}
}
}
export default CheckButtonReducer;
