import * as actionTypes from './actionTypes';
import axios from 'axios';
import { NewUser, NotNewUser } from '../../store/actions/NewUser';
import { firebaseObj } from '../../firebase';


axios.defaults.baseURL = "https://us-central1-mandla-demo.cloudfunctions.net/api";

export const authStart = () => {
	return {
		type: actionTypes.AUTH_START,
	};
};

export const authSuccess = token => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		token: token,
	};
};

export const authFail = (error, isLogin) => {
	if (isLogin) {
		return {
			type: actionTypes.AUTH_FAIL_LOGIN,
			error: error,
		};
	} else {
		return {
			type: actionTypes.AUTH_FAIL_SIGNUP,
			error: error,
		};
	}
};

export const logout = () => {
 	// logout from firebase
 	firebaseObj.auth().signOut().then(() => {
 		// Sign-out successful and remove localstorage.
 		localStorage.removeItem('token');
 		localStorage.removeItem('username');
 		localStorage.removeItem('expirationDate');
 	})
 	return {
 		type: actionTypes.AUTH_LOGOUT,
 	};
}

export const checkAuthTimeout = expirationTime => {
	return dispatch => {
		setTimeout(() => {
			dispatch(logout());
		}, expirationTime * 1000);
	};
};

export const authLogin = (email, password, dateAccessed) => {
	return dispatch => {
		dispatch(authStart());

		axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
		axios.defaults.xsrfCookieName = 'csrftoken';

		axios.defaults.headers = {
			'Content-Type': 'application/json',
			Authorization: '',
		};


		axios
			.post("https://us-central1-mandla-demo.cloudfunctions.net/api/login", {
				email,
				password,
				dateAccessed
			})
			.then(res => {

				firebaseObj
					.auth()
					.signInWithEmailAndPassword(email, password)
					.then((data) => {
						console.log('data =>', data)
					}).catch(err => {

					})

				const token = res.data.token;
				const expirationDate = new Date(new Date().getTime() + 24 * 3600 * 1000);
				localStorage.setItem('token', token);
				// username should be sent back with token
				localStorage.setItem('username', res.data.username);
				localStorage.setItem('expirationDate', expirationDate);
				dispatch(authSuccess(token));
				dispatch(checkAuthTimeout(3600));
				dispatch({type:"NotClickedStartLearning"})
				//add a dispatch for not new user
			})
			.catch(err => {
				dispatch(authFail(err, true));
			});
	};
};



export const authGoogle = (idToken) => {

	return dispatch => {
		dispatch(authStart());

		axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
		axios.defaults.xsrfCookieName = 'csrftoken';

		axios.defaults.headers = {
			'Content-Type': 'application/json',
			Authorization: '',
		}

		axios.post('/sociallogin', {
			method: "GOOGLE",
			token: idToken
		}).then((res) => {
			const token = res.data.token
			const expirationDate = new Date(new Date().getTime() + 24 * 3600 * 1000);
			localStorage.setItem('token', token);
			localStorage.setItem('username', res.data.username);
			localStorage.setItem('expirationDate', expirationDate);
			dispatch(authSuccess(token));
			dispatch(checkAuthTimeout(3600));
		})
			.catch(err => {
				dispatch(authFail(err, true));
			});
	};


}

export const authSignup = (username, email, password, firstName, lastName, location, startingDate) => {
	return dispatch => {
		dispatch(authStart());


		axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
		axios.defaults.xsrfCookieName = 'csrftoken';

		axios.defaults.headers = {
			'Content-Type': 'application/json',
			Authorization: '',
		};

		axios
			.post("https://us-central1-mandla-demo.cloudfunctions.net/api/signup", {
				username,
				email,
				password,
				firstName,
				lastName,
				location,
				startingDate
			})

			.then(res => {
				const token = res.data.token;
				firebaseObj
					.auth()
					.signInWithEmailAndPassword(email, password)
					.then((data) => {
					}).catch(err => {

					})
				// console.log(token + "TOKEN auth.js");
				const expirationDate = new Date(new Date().getTime() + 24 * 3600 * 1000);
				localStorage.setItem('token', token);
				localStorage.setItem('username', username);
				localStorage.setItem('expirationDate', expirationDate);
				dispatch(authSuccess(token));
				dispatch(checkAuthTimeout(3600));

				axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
				axios.defaults.xsrfCookieName = 'csrftoken';

				axios.defaults.headers = {
					'Content-Type': 'application/json',
					Authorization: `Token ${token}`,
				};

			})
			.catch(err => {
				dispatch(authFail(err, false));
			});
	};
};

export const authCheckState = () => {
	return dispatch => {
		const token = localStorage.getItem('token');
		if (token === undefined) {
 			dispatch(logout());
 		} else {
 			dispatch(authSuccess(token));
 			// const expirationDate = new Date(localStorage.getItem('expirationDate'));
 			// if (expirationDate <= new Date()) {
 			// 	dispatch(logout());
 			// } else {
 			// 	// dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
 			// }
 		}
 	};
 };
