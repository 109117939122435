const initialState ={
    checkButtonClicked:null
}


 const CheckButtonReducer =(state=initialState,action)=>{
     const {type,payload}=action
switch(type){
    case "Clicked":
        return {checkButtonClicked:true};

   case "NotClicked":
        return {...state,checkButtonClicked:false}

    default:
        return {...state,checkButtonClicked:false}
}
}
export default CheckButtonReducer;
