import React from 'react';
import {Button} from 'react-bootstrap'




function stateInitializer (props, type, self){
  let state = {
    inputValue : [],
    submittedWordsButtons : [],
    wordButtons : [],
    wordButtonsSet : new Set(),
    key : 0
  };

  let x =0;
  if (type === "word"){
    var splitWordArr= props[0].word.split(" ");
	  var splitWordArr2= props[1].word.split(" ");
	  var splitWordArr3= props[2].word.split(" ");
  }else if (type === "description") {
    var splitWordArr= props[0].description.split(" ");
	  var splitWordArr2= props[1].description.split(" ");
	  var splitWordArr3= props[2].description.split(" ");
  }
    
    while(x< splitWordArr.length){
      let word = splitWordArr[x];
      let word2 = splitWordArr2[x];
      let word3 = splitWordArr3[x];


      if(x<splitWordArr.length && !state.wordButtonsSet.has(word)){
        let newWordButtons = state.wordButtons;
        newWordButtons.push(
          <Button onClick ={()=>dragToLine(word, self)}  style={{ marginRight: "10px",backgroundColor:'white', color:'black',borderColor:'#e5e5e5',fontWeight:'bold',borderRadius:'50px', borderWidth:'2px' }}>
          {splitWordArr[x]}
          </Button>);
        state.wordButtonsSet = state.wordButtonsSet.add(word);
        state.wordButtons = newWordButtons;
    }
      if(x<splitWordArr2.length&& !state.wordButtonsSet.has(word2)){
        let newWordButtons = state.wordButtons;
        newWordButtons.push(
          <Button onClick ={()=>dragToLine(word2, self)}  style={{ marginRight: "10px",backgroundColor:'white', color:'black',borderColor:'#e5e5e5',fontWeight:'bold',borderRadius:'50px', borderWidth:'2px' }}>
        {splitWordArr2[x]}
        </Button>
        )
        state.wordButtonsSet = state.wordButtonsSet.add(word2);
        state.wordButtons = newWordButtons;
        
    }
    if(x<splitWordArr3.length && !state.wordButtonsSet.has(word3)){
			let newWordButtons = state.wordButtons;
			newWordButtons.push(
				<Button onClick ={()=>dragToLine(word3, self)} style={{ marginRight: "10px",backgroundColor:'white', color:'black',borderColor:'#e5e5e5',fontWeight:'bold',borderRadius:'50px', borderWidth:'2px' }}>
			{splitWordArr3[x]}
			</Button>
			)
			state.wordButtonsSet = state.wordButtonsSet.add(word3);
      state.wordButtons = newWordButtons;
	}
    x++;
    
  }
  return state;

}


function dragToLine (word, self) {
  let key = self.state.key;
  let newbutton =	<Button key={key} onClick ={()=>removeFromLine(key, self)} style={{ marginRight: "10px",backgroundColor:'white', color:'black',borderColor:'#e5e5e5',fontWeight:'bold',borderRadius:'50px', borderWidth:'2px' }}>
    {word}
    </Button>;
  let newState = self.state;
  let newInputValue = self.state.inputValue;
  newInputValue.push(word);
  let newSubmittedWordsButtons = self.state.submittedWordsButtons;
  newSubmittedWordsButtons.push(newbutton);
  newState.inputValue = newInputValue;
  newState.submittedWordsButtons = newSubmittedWordsButtons;
  newState.key +=1 ;
  self.setState(newState);
}


function removeFromLine(key, self){
  let newState = self.state;
  var currWords = self.state.submittedWordsButtons;
  var currInput = self.state.inputValue;

  var newWords = [];
  var newInput = [];

  for (let i = 0; i<currWords.length; i++){
    if (key != currWords[i].key){
      newWords.push(currWords[i]);
      newInput.push(currInput[i]);
    }
  }
  newState.submittedWordsButtons = newWords;
  newState.inputValue =  newInput;
  self.setState(newState);
}




export {dragToLine, removeFromLine, stateInitializer};