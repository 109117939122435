import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import {Row, Image, Button, Container} from 'react-bootstrap';
import styles from './Home.module.css';
import Header from '../../containers/Home/Header/Header';
import Welcome from '../../components/Home/Welcome/Welcome';
import LeftColumn from '../../components/Home/LeftColumn/LeftColumn';
import CourseCard from '../../components/Home/CourseCard/CourseCard';
import LanguageCard from '../../components/Home/LanguageCard/LanguageCard';
import {Link} from 'react-router-dom';
import LangAboutComponent from '../../components/Home/LanguageCard/LangAboutComponent';
import { firebaseObj } from "../../firebase"

import * as profileActions from '../../store/actions/profile';

//added
import * as actions from '../../store/actions/auth';
import { NotNewUser } from '../../store/actions/NewUser';

export class Home extends Component {

	state = {
		language: this.props.match.params.language,
    flag:null,
    about:null,
    region:null,
    learnerCount:null,
    correctLearnURL:null,
    availWeb:null,
	};

	componentDidMount() {
      this.loadFlag();
		this.props.NotNewUser();

    this.chooseURLS();
		document.title = 'Dashboard - Mandla';
	}

	componentDidUpdate(prevProps, prevState) {

	}
  selectDisplayButton = () => {
    if(this.state.availWeb){
      return (
      <Link to={this.state.correctLearnURL}>
      <Button className={styles.LearnButton}> Learn {this.state.language} on Mandla </Button>
      </Link>);

    }
    else{
      return (
        <div className={{paddingBottom: '15px'}}>
          
        {/* <Row style={{color:"gray"}}>
        {this.state.language} content is still under development.
        </Row> */}
        
        <Link to="/mail">
      <Button className={styles.LearnButton}> Be the first to know when {this.state.language} is on Mandla !</Button>
      </Link>
    </div> );
    }
  }
chooseURLS = () => {
  var user = firebaseObj.auth().currentUser;
  if (user){
    let url= "/courses/"+ this.state.language+"/"
    this.setState({
    correctLearnURL:url,
  });
  }
  else {

      let url= "/newUser/"+ this.state.language+"/Survey";
    this.setState({
    correctLearnURL:url,
  });

  }
}
  loadFlag = () => {

		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";
		const lang = this.state.language

		axios.get(url + '/language/' + lang).then(res => {

			this.setState({
        flag: res.data.flag,
        about:res.data.about,
        region:res.data.region,
        learnerCount:res.data.learnerCount,
      availWeb:res.data.availWeb,});


		});
	};

	render() {
    console.log('tor',this.props.match.url, this.props.profile, this.props.isActive, 't' );


		return (
			<Container fluid className ={styles.ViewController}>
				<Header url={this.props.match.url} profile={this.props.profile} color='white' LogoSwitch={true} textColor='black' />

					<div >

				<div >

				<Row style={{marginLeft:'60px', textAlign:'center', width:'100%'}}>

				<div style = {{color:'white', fontSize:'30px'}}>

				</div>
					</Row>

				<div style = {{color:'white', fontSize:'30px', fontWeight:'bold'}}>

				</div>


					<div className={styles.ContainerMain}>


						<div className={styles.RightColumn}>


					<LangAboutComponent name={this.state.language} flag={this.state.flag} about={this.state.about} region={this.state.region} learnerCount={this.state.learnerCount}/>
<Row style={{display:'block', width: '100%', textAlign: 'center'}}>
{this.selectDisplayButton()}
{/**
  <Link to = {this.state.correctChatroomURL}/>
  <Button> Open the Mandla {this.state.language}  Chatroom </Button>
  </Link>
  <Link to = {this.state.correctForumURL} />
  <Button> Visit the Mandla  {this.state.language} Forum</Button>
  </Link>
**/}

</Row>
							</div>

					</div>

</div>
						</div>
			</Container>
		);
	}
}

const mapStateToProps = state => {
	return {
		profile: state.profile,
		token: state.auth.token,
		langAboutPopupWasOpened:state.langAboutPopupWasOpened,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProfile: () => dispatch(profileActions.profileLoad()),
		NotNewUser: ()=> dispatch(NotNewUser()),

	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Home);
