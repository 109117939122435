import {Button} from 'react-bootstrap'
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './CourseCard.module.css';

const QuitCourseModal = React.lazy(() => import('../../QuitCourseModal/QuitCourseModal'));


class CourseCard extends Component {
	state = {
		modal: false,
	};

	openModal = () => {
		this.setState({ modal: true });
	};

	closeModal = () => {
		this.setState({ modal: false });
	};

	learn = () => {
		if (Number(this.props.course.totalWords) !== 0) {
			this.props.learn(this.props.course.id);
		}
	};

	render() {
		const course = this.props.course;

		if (this.props.loading) {
			return <div className={styles.CourseCard + ' ' + styles.LoadingBox} />;
		} else {
			let progress = 0;
			let nextUpButtonClasses = styles.NextUpButton + ' ' + styles.Disabled;

			if (Number(course.totalWords) !== 0) {
				progress = (100 * Number(course.wordsLearned)) / Number(course.totalWords);
				nextUpButtonClasses =
					progress === 100 ? styles.NextUpButton + ' ' + styles.Disabled : styles.NextUpButton;
			}
			const progressWidth = { width: progress + '%' };

			return (
				<div className={styles.CourseCard}>
					{this.state.modal && (
						<Suspense fallback={null}>
							<QuitCourseModal
								closeModal={this.closeModal}
								quitCourse={() => this.props.quitCourse(course.id)}
							/>
						</Suspense>
					)}
					<div className={styles.CardTop}>

						<div className={styles.CardMainContainer}>
							<div className={styles.TitleRow}>
								<Link className={styles.CourseTitle} to={'/course/' + course.id}>
									{course.name}
								</Link>
								<div className={styles.MoreOptionsDiv}>
									<div className={styles.MoreOptionsButton} />
									<div className={styles.TooltipWrapper}>
										<ul className={styles.Tooltip}>
											<li>
												<Link className={styles.TooltipLink} to={'/course/' + course.id}>
													Course details
												</Link>
											</li>
											<li>
												<div onClick={this.openModal} className={styles.TooltipLink}>
													Quit course
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div className={styles.ProgressBar}>
								<div style={progressWidth} className={styles.Progress} />
							</div>
							{progress === 100 && <div className={styles.CourseCompleted}>Course completed!</div>}
						</div>
					</div>
					<div className={styles.CardBottom}>
						{/* <div className={styles.MoreButton + ' ' + styles.Disabled}>
							<div className={styles.MoreButtonIcon} />
							<div className={styles.MoreButtonText}>More</div>
						</div> */}
						<div onClick={() => this.learn()} className={nextUpButtonClasses}>
	<Button>
							<div className={styles.NextUpLabel}>

								<div>Continue Learning</div>
							</div>
							<div className={styles.NextUpArrow} />
										</Button>
						</div>

					</div>
				</div>
			);
		}
	}
}

export default CourseCard;

const conditionalCheck = function(props, propName, componentName) {
	if (!props.loading && !props[propName]) {
		return new Error(
			'Invalid prop `' +
				propName +
				'` supplied to' +
				' `' +
				componentName +
				'`. Validation failed.',
		);
	}
};

CourseCard.propTypes = {
	loading: PropTypes.bool,
	course: conditionalCheck,
	learn: conditionalCheck,
	quitCourse: conditionalCheck,
};

CourseCard.defaultProps = {
	loading: false,
};
