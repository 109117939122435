import React, { Component } from 'react';
import {android, ios} from 'platform-detect'
import Header from '../../components/Header/Header';
import {Helmet} from 'react-helmet'


class Share extends Component {
	componentDidMount() {
		//Check if android or ios
        //if ios
        if(ios){
            window.location.replace("https://apps.apple.com/us/app/mandla/id1567445203");
        }
        //if android
        else if(android){
            window.location.replace("https://play.google.com/store/apps/details?id=com.sebmita.sebmita");
        }
       
       
	}

	render() {
		return (
			<React.Fragment>
        <h1>Hello!</h1>
        <p> I'm using the Sebmita App to learn African languages and would love for you to join me!</p>
        <p>Download the app from the link below and join me in learning!</p>
        <a href = "https://play.google.com/store/apps/details?id=com.sebmita.sebmita"> Google Play Store</a>
        <a href= "https://apps.apple.com/us/app/mandla/id1567445203" > Apple App Store</a>
			</React.Fragment>
		);
	}
}

export default Share;
