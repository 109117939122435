import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import axios from 'axios';
import * as profileActions from '../../../store/actions/profile';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

function LanguageOptionsMenu (props) {
function alertBox(){

}
function removeLanguage () {
  let alertmsg= "Are you sure you would like to remove " +props.lang +"? Your Progress will not be saved."
  if(window.confirm(alertmsg)){
    console.log("Removing");
        delete props.profile.progress.progressItems[props.lang]
          const profileProgress = JSON.parse(JSON.stringify(props.profile.progress));
            console.log(profileProgress.progressItems);
    console.log("Deleted");
      const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";
      axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
      axios.defaults.xsrfCookieName = 'csrftoken';

      axios.defaults.headers = {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + props.token,
      };

      if (props.token){
        axios
        .put(url + '/profile/update-progress/', profileProgress)
        .then(res => props.updateProfile());

        axios
			.put(url + '/updateCount/decrease/' + props.lang)
      }
      else{}

      }

  }




function loadAboutDetails () {


  let aboutTo = {
    pathname:"/About/"+props.lang,
    region: props.region ,
    learnerCount:props.learnerCount ,
    flag: props.flag,
    about: props.about,
  }
  return aboutTo;
}

return (


<table style={{width:"190px", position:'relative'}}>
	<div style={{color:"black", backgroundColor:"white", width:"100px", textAlign:"center"}}>

<tr>
  <div style={{fontWeight:"bold", width:"190px", fontSize:"20px", cursor:"pointer"}} onClick={()=>removeLanguage()}>

Remove

  </div>
</tr>

<tr>
  <Link to= {loadAboutDetails()}>
  <div style={{fontWeight:"bold", fontSize:"20px", cursor:"pointer"}}>
About
  </div>
    </Link>
</tr>

<tr>
<Link to= {"/courses/"+props.lang}>
  <div style={{fontWeight:"bold", fontSize:"20px", cursor:"pointer"}}>
Learn
    </div>
  </Link>
  </tr>

</div>

</table>



);
}
const mapStateToProps = state => {
	return {
		profile: state.profile,
		token: state.auth.token,
		newUser: state.newUser.newUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
	updateProfile: () => dispatch(profileActions.profileLoad()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LanguageOptionsMenu);
