
import ReconnectRow from './ReconnectRow/reconnectrow';
import CardsRow from './CardsRow/CardsRow'
import wave from './img/wave.png'
import person1 from '.././img/person1.png';
import person2 from '.././img/person2.png';
import fist from '.././img/fist.png';
import styles from './SecondHalfStyles.css'
import AppStoreIcon from './img/apps.png';
import PlayStoreIcon from './img/plays.png';
import { Row, Container, Image, Button, Col, Card} from 'react-bootstrap';

const SecondHalf= props => {
  return (
    <Container>


      {  /** <Image src={wave}rounded className={styles.Wave}/>* */}

  <Container className={styles.ReconnectRow}>
  <Row>
  <Col>
  <Col>
          <div className="tiles-item reveal-from-right" data-reveal-delay="200">
            <div className="tiles-item-inner">
            <Row >
            <Col>

            <Image src={person1}rounded className="mx-auto my-4" />
            </Col>
            <Col>
                <Image src={person2}rounded className="mx-auto my-4" />

              </Col>
              </Row>
              <Row >
              <div className="testimonial-item-footer text-xs mt-30 mb-0 ">
                <h4 style={{color: 'black',}}>About</h4>
              <p className="text-sm mb-0">

 </p> Mandla is an innovative, black owned e-learning
  language app created for those who seek to strengthen their unbreakable connection with their African
   roots.
              </div>

              </Row>
            </div>
          </div>

  </Col>
  </Col>
  <Col>
  <Col>
  <Col>

            <Row >
            <Col>
            <Image src={fist}rounded className="mx-auto my-4" width={100} />
              </Col>
              </Row>

              <Row >
              <div className="testimonial-item-footer text-xs mt-30 mb-0 ">
                <h4 style={{color: 'black',}}>Our Name</h4>
              <p className="text-sm mb-0">

   Our name comes from the word "Amandla" ("power" in Zulu & Xhosa), a rallying cry in the face of apartheid-era oppression. Mandla strives to preserve and promote African cultures through language, empowering our community of thinkers, dreamers and doers everywhere.
                 </p>
              </div>
              </Row>


  </Col>
  </Col>

  </Col>
  </Row>

</Container>
    <CardsRow/>
    <Row>
    <Col>
    <a href = "https://apps.apple.com/us/app/mandla/id1567445203" target="_blank">
    <Image src={AppStoreIcon} style={{width:'200px', height:'70px', margin:'auto'}}/>
    </a>
    </Col>
    <Col>
      <a href = "https://play.google.com/store/apps/details?id=com.sebmita.sebmita" target="_blank">
    <Image src={PlayStoreIcon} style={{width:'200px', height:'70px',margin:'auto'}}/>
        </a>
    </Col>
    </Row>
    </Container>
  );
}
export default SecondHalf
