import React from 'react';
import PropTypes from 'prop-types';
import {Row, Image,Container, Col} from 'react-bootstrap'
import styles from './StudyTheWord.module.css';
import soundIcon from './img/sound.png';
import ReactAudioPlayer from 'react-audio-player';
import {connect }from 'react-redux'
import checkView from './CheckViewPort'
import SoundIcon from './translationIcon.png'


import {correctAnswerSelected, incorrectAnswerSelected} from '../../.././store/actions/AnswerCheckActions'

function isPlaying(audio) { return !audio.paused; }

function playAudio(audio){
	if (isPlaying(audio)){
		audio.pause()
		audio.currentTime = 0
	}

	audio.play()
}

const StudyTheWord = (props) => {
	var repeatWordAudio = new Audio (props[0].audio)

	const select = (audio) => {
		playAudio(audio)
	}

	props.correctAnswerSelected();
	return(

	<div style={{width:'100vw',height:'70vh', backgroundColor:'white', overflow: 'scroll'}}>
		<div >

		<div className={styles.challenge + styles.challengeTranslate}>
				<h1 className={styles.challengeHeader} styles={{marginTop:'70px'}}>
			Study the Following </h1>
				</div>

<Container>
<Row className="justify-content-center">
<div className={styles.WordCard}>

	<div className={props[0].context ? styles.WordCardInner : null}>
		<div className={props[0].context ? styles.WordCardFront : null}>
		<Image src={props[0].image} style={{  width:'240px', height:'245px', margin:'auto', borderRadius:'10px', }}/>
		<Row className = {props[0].context? styles.WordCardIndicator : styles.WordCardNoIndicator}>
		<Col><div className={styles.textHolder}>{checkView()}</div></Col>
		</Row>
		</div>
		<div className={props[0].context ? styles.WordCardBack : null}>
		<span className={styles.Context}><strong>{props[0].context}</strong> </span>
		</div>
	</div>

{/*<div className={styles.soundIcon}>

<img className={styles.soundIconImg}src={soundIcon}/></div>*/}
</div>
</Row>
<ReactAudioPlayer
  src={props[0].audio}
  autoPlay
style={{backgroundImg:{soundIcon}}}
className={styles.playAudio}
/>

<Row style={{paddingTop: '10px', textAlign: 'center'}}className="justify-content-center">
<div className={styles.Word}>{props[0].word}</div>
</Row>
<Row className="justify-content-center">
<div className={styles.orangeLine}/>
</Row>
<Row className="justify-content-center">
<div className={styles.Description}>{props[0].description}</div>
</Row>
<Row className="justify-content-center">
<Image onClick ={()=>select(repeatWordAudio)} src={SoundIcon} style={{marginTop: '5px', height: '25px', width:' 25px', cursor:'pointer'}}></Image>
</Row>






</Container>
		</div>
	</div>

);
}
const mapDispatchToProps=(dispatch)=>{
	return {correctAnswerSelected:()=>dispatch(correctAnswerSelected()), incorrectAnswerSelected:()=>dispatch(incorrectAnswerSelected())}


}
export default connect(null,mapDispatchToProps)(React.memo(StudyTheWord));


StudyTheWord.propTypes = {
	word: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	next: PropTypes.func.isRequired,
};
