const initialState ={
    newUser:true
}


 const NewUserReducer =(state=initialState,action)=>{
     const {type,payload}=action
switch(type){
    case "ClickedStartLearning":
        return {newUser:true};

   case "NotClickedStartLearning":
        return {...state,newUser:false}

    default:
        return {...state,newUser:state.newUser}
}
}
export default NewUserReducer;
