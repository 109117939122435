// import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
    apiKey: "AIzaSyDtMEXlQAmUFMyJaO9kTDarGPiM7rn2pQY",
    authDomain: "mandla-demo.firebaseapp.com",
    projectId: "mandla-demo",
    storageBucket: "mandla-demo.appspot.com",
    messagingSenderId: "1069863514519",
    appId: "1:1069863514519:web:d7955722fefb9c39ca94d2",
    measurementId: "G-7WKCEGY82Z"
};


// const provider = new firebase.auth.GoogleAuthProvider()
// const fbProvider = new firebase.auth.FacebookAuthProvider()

firebase.initializeApp(config);

export const firebaseObj = firebase;
// export const firestore = firebase.firestore();


// export const signInWithGoogle = () => {

//     auth.signInWithPopup(provider)
//     var user = auth.currentUser;
//     const expirationDate = new Date(new Date().getTime() + 24 * 3600 * 1000);

//     if (user != null) {
//     var username = user.email;


//         user.getIdToken(true).then((idToken) => {
//             /*
//             axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
//             axios.defaults.xsrfCookieName = 'csrftoken';

//             axios.defaults.headers = {
//                 'Content-Type': 'application/json'
//             }

//             axios.post('/sociallogin', {
//                 method: "GOOGLE",
//                 token: idToken
//             }).then((res) => {
//                 const token = res.data.token
//                 console.log(2, token)
//                 const expirationDate = new Date(new Date().getTime() + 24 * 3600 * 1000);
//                 localStorage.setItem('token', token);
//                 localStorage.setItem('username', res.data.username);
//                 localStorage.setItem('expirationDate', expirationDate);
//                 authSuccess(token);
//                 checkAuthTimeout(3600);
//             })
//             .catch((error) => {
//                 console.log(error)
//                 authFail(error, true)
//             })
//             */
//         });
//     }

// }

// export const signInWithFacebook = () => {
//     auth.signInWithPopup(fbProvider)
// }
