import React from 'react';
import styles from './progressBar.module.css';
import PropTypes from 'prop-types';
import {Row} from 'react-bootstrap'

const ProgressBar = props => {
    return (
        <Row className="justify-content-center" style={{paddingBottom: '20px'}}>
        <div className={styles.progressBar}>
            <Filler percentage = {props.percentage} />
        </div>
        </Row>
    )
}

const Filler = props => {
    
    return <div className={styles.filler} style={{ width: `${props.percentage}%` }}>

    </div>

  }


export default React.memo(ProgressBar);

  ProgressBar.propTypes = {
      percentage: PropTypes.number
  }
