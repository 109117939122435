import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import styles from './Learn.module.css';
import Spinner from '../../components/Spinner/Spinner';
import Header from '../../components/Learn/Header/Header';
import { NewUser, NotNewUser } from '../../store/actions/NewUser';

import SessionComplete from '../../components/Learn/SessionComplete/SessionComplete';
import Helmet from 'react-helmet'
import GameHeader from '../../components/Learn/GameHeader/GameHeader';
import GameFooter from '../../components/Learn/GameFooter/GameFooter';
import GameBottomHalf from '../../components/Learn/GameBottomHalf/GameBottomHalf';
import ProgressBar from '../../components/Learn/ProgressBar/ProgressBar.js'
import {correctAnswerSelected, incorrectAnswerSelected} from '../../store/actions/AnswerCheckActions'
import {incrementScore, decrementScore, unchangedScore, resetScore} from '../../store/actions/ScoreActions'
import {checkButtonHasBeenClicked, checkButtonHasNotBeenClicked} from '../../store/actions/CheckButtonActions'
import * as profileActions from '../../store/actions/profile';

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

function shuffle(array) {
	var currentIndex = array.length,  randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {

	  // Pick a remaining element...
	  randomIndex = Math.floor(Math.random() * currentIndex);
	  currentIndex--;

	  // And swap it with the current element.
	  [array[currentIndex], array[randomIndex]] = [
		array[randomIndex], array[currentIndex]];
	}

	return array;
  }


const GOAL_SCORE = 2;
const TOTAL_TURNS = 15;
const PASSING_PERCENTAGE =0.7

class Learn extends Component {
	state = {
		courseId: this.props.match.params.courseId,
		course: null,
		courseLength: 0,
		loading: true,
		index: 0,
		turns: TOTAL_TURNS,
		passingpercentage: PASSING_PERCENTAGE,
		result: 'learning',
		progress: null,
		allCourseWords:null,
		sessionWords: null,
		currentWord: null,
		currentWord2: null,
		currentWord3: null,
		answerCorrectness: false,
		courseFinished: false,
		footerIndex:0,
		percentage: 0,
		isIntro: false,
		order: shuffle([0,1,2]),
		score:0,
		passedLesson:false,
		denominator: TOTAL_TURNS,
		part:this.props.match.params.part,
		newUser:false,
		percentageIncrementVal:3.5,
	};


	componentDidMount() {
		let incrementVal = TOTAL_TURNS*0.12;
		this.setState({
				percentageIncrementVal:incrementVal,
		});

		this.loadCourse();
		if (!this.props.profile.loading || this.props.newUser) {
			this.loadProgress();
		}

	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.profile.loading && prevProps.profile.loading) {
			this.loadProgress();
		}
		if (!this.sessionCreated && this.state.progress && this.state.course) {
			this.sessionCreated = true;
			this.createLearningSession();
		}
		//Checks if complete to reset global score state to 0

	}

	createLearningSession = (state = this.state) => {
		const course = state.course;
		const sessionWords = [];
		const allCourseWords = [];
		let isIntro = false;
		let numTurns= this.state.turns;
		let footerIndex= this.state.footerIndex;

		//Determines if the course is an introductory lesson or not
		if(course.name==="Introduction"){
			isIntro=true;
			numTurns=course.words.length;
			footerIndex=3;
		}
		for (let pair of course.words) {
			pair.word = pair.word.capitalize()
      pair.description = pair.description.capitalize();
			allCourseWords.push(pair);

			}
			let x =0;
		//Load progress object to get access to parts
		let languageMap = this.props.profile.progress.progressItems[course.teaching];
		//Courses submap Map
		let parts=0;


//If parts == 1 session words gets 1st 5, if parts == 2 session words gets 10-15, if parts == 3 session words gets 15-20, etc.
	let y=0;
	let endIndex=this.state.part*5;
	if(endIndex==0){
		endIndex=5;
	}
	while(y<allCourseWords.length){

	if(endIndex>allCourseWords.length){
		endIndex = allCourseWords.length;

}
//The number of words tested in each lesson, I am too lazy to
//add it to state object at the moment  so it can be accessed
//but that would be cleaner
let numWordsTested = 5;


if((endIndex-5)<y && (y<endIndex)){
				sessionWords.push(allCourseWords[y]);

				// console.log(allCourseWords[y] );

				// console.log(allCourseWords[20]);
			}

		y++;
	}
	if(sessionWords.length<5 && allCourseWords.length>5){
		let count =0;
		while(count<5){
			sessionWords.push(allCourseWords[count]);
			count++;
		}
	}


		//Progress does not get updated

		if (sessionWords.length === 0) {
			this.props.history.goBack();
		} else {
			const currentWord = sessionWords[0];
			const currentWord2 = sessionWords[1];
			const currentWord3 = sessionWords[2];
			this.setState({
				loading: false,
				sessionWords: sessionWords,
				currentWord: currentWord,
				currentWord2: currentWord2,
				currentWord3: currentWord3,
				isIntro:isIntro,
				turns:numTurns,
				footerIndex:footerIndex,
			});
		}
	};

	loadProgress = (props = this.props) => {
		if (!props.profile.loading || this.props.newUser) {
			let progress = props.profile.progress[this.state.courseId];

			if (!progress) {
				progress = {  wordsInProgress: {} };
			} else {
				progress = JSON.parse(JSON.stringify(progress));
			}
			this.setState({ progress: progress });
		}
	};

	loadCourse = () => {
		// console.log(window.location.pathname);
		// if(window.location.pathname.includes("newUser")){
		// 	console.log("ok");
		// 	this.setState({
		// 		newUser:true,
		// });
		//
		// }
		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";

		axios.defaults.xsrfCookieName = 'csrftoken';
		axios.defaults.xsrfHeaderName = 'X-CSRFToken';
		let correctRoute;

if(window.location.pathname.includes("newUser")){
	correctRoute = "/introlesson/"+this.state.courseId +"/";
this.setState({
	turns: 10,
	denominator:10,
	percentageIncrementVal:9,

});
}
else{
		correctRoute = '/course/' + this.state.courseId + '/'
		console.log(correctRoute);
}
		axios.get(url + correctRoute).then(res => {
			const course = res.data;


			if (course.words.length === 0) {
				this.props.history.goBack();
			}

			this.setState({
				course: course,
				courseLength:course.words.length,
			});
		});
	};

	nextClick = () => {
		//Update user Score
		// Passses global score state to learn's score state
		this.updateUserScoreState();
		//Check if lesson has been passed or not

		//Resets global score state to 0 if it was last question of the lesson
			if (this.state.turns === 1 || this.state.courseFinished) {
						// Resets global score to 0
						this.props.resetScore();
					}
		const turns = this.state.turns - 1;
		const percentage = this.state.percentage + this.state.percentageIncrementVal;
			const sessionWords = JSON.parse(JSON.stringify(this.state.sessionWords));
		let idx = this.state.index;
		let idx2 = 0;
		let idx3 = 0;
		if (turns === 0) {
			this.setState({ turns: 0 });
		} else {
			if(this.state.isIntro || this.state.course == "Numbers"){
				if (idx<sessionWords.length) {
					idx++;
				}
			}
			else{

        //Chronological Progresion for study words but random selction otherwise
        if(this.state.turns>25){
        if(sessionWords.length>1){
        if (idx+2<sessionWords.length-1) {
          idx++;
        }
  }
  idx2 = idx+1;
  idx3 = idx2+1;
  }
  else {
    //logic for randmoly selecting next word
			if (sessionWords.length > 1) {
        while (sessionWords[idx].word === sessionWords[this.state.index].word || sessionWords[idx2].word === sessionWords[idx].word || sessionWords[idx2].word === sessionWords[idx3].word || sessionWords[idx3].word === sessionWords[idx].word ){
          idx = Math.floor(Math.random() * sessionWords.length);
          idx2 = Math.floor(Math.random() * sessionWords.length);
          idx3 = Math.floor(Math.random() * sessionWords.length);
        }
			}
		}
  }
		this.props.checkButtonHasNotBeenClicked()
			this.setState({
				turns: turns,
				index: idx,
				sessionWords: sessionWords,
				currentWord: sessionWords[idx],
				currentWord2: sessionWords[idx2],
				currentWord3: sessionWords[idx3],
				footerIndex:0,
				percentage: percentage,
				order: shuffle([0,1,2])
			});
		}
	};
updateUserScoreState = () =>{
			this.setState({ score: this.props.userScore });

}
updatePassedLessonState = () =>{
	this.setState({ passedLesson: true});
}

	checkAnswer = () => {
			const turns = this.state.turns - 1;
			//Used to refresh state of the footer index
			let newIndex =0;

			//If selected answer is the correct one

			if(this.props.selectedAnswer){
				//Used to keep increment user's score if they chose the correct answer
				this.props.incrementScore();
				newIndex =1;
			}
			else{
				//Redundant but redunancy is good
				this.props.unchangedScore();
				newIndex=2;
			}
			this.setState({
				footerIndex:newIndex,
			});
			//Resets choice to incorrect selection on check button re render
			this.props.incorrectAnswerSelected();
			this.props.checkButtonHasBeenClicked()

		}

	goToCourse = () => {
		this.props.history.push('/course/' + this.state.courseId);
	};
	goToCourses = () => {
		this.props.history.push('/courses/' + this.state.course.teaching);
	};




	render() {
		if (this.state.course && !this.state.loading) {
			const pair = [this.state.currentWord, this.state.currentWord2, this.state.currentWord3];
			const footerState = [this.state.footerIndex];

			let content =
			<div>

						<GameHeader next={this.nextClick}  check={this.checkAnswer} close={this.props.history.goBack} turns={this.state.turns}/>
						<ProgressBar percentage={this.state.percentage} />


			<div>
			<GameBottomHalf next={this.nextClick} check={this.checkAnswer} {...pair}  for={this.state.course.for} teaching= {this.state.course.teaching} turns={this.state.turns} order={this.state.order} courseId={this.state.courseId} isIntro={this.state.isIntro} />



			<GameFooter next={this.nextClick} check={this.checkAnswer} index={this.state.footerIndex}/>
			</div>


			</div>;

			if (this.state.turns === 0 || this.state.courseFinished) {

				content =		(<SessionComplete courseFinished={this.state.courseFinished} course={this.state.course} courseID={this.state.courseId} NumWords={this.state.courseLength} Profile={this.goToCourse} Courses={this.goToCourses} Passed={this.state.passedLesson} score={this.state.score.userScore} denom={this.state.denominator} passingpercentage={this.state.passingpercentage} lang={this.state.course.teaching} loggedIn={!this.props.newUser ? true: false}/>
				);
			}
			return (
				<React.Fragment>
					<Header

						close={this.props.history.goBack}

					/>
					{content}
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<Helmet>
					<meta name="apple-itunes-app" content="app-id=1567445203, app-argument=myURL" /> 
					</Helmet>
					<div className={styles.SpinnerWrapper}>
						<Spinner />
					</div>
				</React.Fragment>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		profile: state.profile,
		token: state.auth.token,
		selectedAnswer: state.selectedAnswer.selectedAnswer,
		checkButtonClicked: state.checkButtonClicked,
		userScore:state.userScore,
		newUser: state.newUser.newUser

	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProfile: () => dispatch(profileActions.profileLoad()),
		checkButtonHasBeenClicked:()=>dispatch(checkButtonHasBeenClicked()),
		 checkButtonHasNotBeenClicked:()=>dispatch(checkButtonHasNotBeenClicked()),
		 incorrectAnswerSelected:()=>dispatch(incorrectAnswerSelected()),
		 incrementScore:()=>dispatch(incrementScore()),
		 decrementScore:()=>dispatch(decrementScore()),
		 unchangedScore:()=>dispatch(unchangedScore()),
		 resetScore:()=>dispatch(resetScore()),
		 newUser:()=>dispatch(NewUser()),
		 notNewUser:()=>dispatch(NotNewUser())
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Learn);
