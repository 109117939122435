import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import { connect } from 'react-redux';
import styles from './LandingPage.module.css'
import Meta from './Meta/meta.js'
import FirstHalf from './FirstHalf/firsthalf'
import SecondHalf from './SecondHalf/secondhalf'
import Footer from './Footer/footer'
import { NewUser, NotNewUser } from '../../store/actions/NewUser';





class LandingPage extends Component {

	render() {

		return (
			<React.Fragment>
				<Meta/>
				<Header url={this.props.match.url} />
				<div className ={styles.Body}>
						<FirstHalf newUser={this.props.newUser}/>
						{/* <SecondHalf/>
						<Footer/> */}
	</div>
			</React.Fragment>
		);
	}
}


const mapStateToProps = state => {
	return {
		newUser: state.newUser.newUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		 newUser: () => dispatch(NewUser()),
		 notNewUser: () => dispatch(NotNewUser())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LandingPage);
