import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import styles from './Languages.module.css';
import Header from '../../components/Header/Header';
import Helmet from 'react-helmet'
import {Row, Image} from 'react-bootstrap';
import firebase from 'firebase';

import * as profileActions from '../../store/actions/profile';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

function abbrNum(number, decPlaces) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10,decPlaces);

    // Enumerate number abbreviations
    var abbrev = [ "K", "M", "B", "T" ];

    // Go through the array backwards, so we do the largest first
    for (var i=abbrev.length-1; i>=0; i--) {

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10,(i+1)*3);

        // If the number is bigger or equal do the abbreviation
        if(size <= number) {
             // Here, we multiply by decPlaces, round, and then divide by decPlaces.
             // This gives us nice rounding to a particular decimal place.
             number = Math.round(number*decPlaces/size)/decPlaces;

             // Handle special case where we round up to the next abbreviation
             if((number == 1000) && (i < abbrev.length - 1)) {
                 number = 1;
                 i++;
             }

             // Add the letter for the abbreviation
             number += abbrev[i];

             // We are done... stop
             break;
        }
    }

    return number;
}


class Languages extends Component {
	state = {
		languagesHTML: null,
		strLanguagesHTML: null,
		newUser:false,
		search: '',
	};

	componentDidMount() {
		if(window.location.pathname.includes("newUser")){
      this.setState({
        newUser:true,

    });
	}
		this.loadLanguages();
		this.checkIfAdded();
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.profile.myLanguages !== prevProps.profile.myLanguages) {
			this.checkIfAdded();
		}
	}

	checkIfAdded = () => {
		if (!this.props.profile.loading) {
			this.setState({ loading: false });
			const languages = this.props.profile.myLanguages;
			let added = false;

			if (languages && languages.length !== 0) {
				for (let c of languages) {
					if (String(c.id) === this.state.name) {
						added = true;
					}
				}
			}
			this.setState({
				added: added,
			});
		}
	};


	addToMyLanguages = (language) => {
		if (!(language in JSON.parse(JSON.stringify(this.props.profile.progress)).progressItems)){
			const profileProgress = JSON.parse(JSON.stringify(this.props.profile.progress));
			profileProgress.progressItems[language] = {courses:null};
			profileProgress.currentLanguage=language;

		const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";
		axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
		axios.defaults.xsrfCookieName = 'csrftoken';

		axios.defaults.headers = {
			'Content-Type': 'application/json',
			Authorization: 'Token ' + this.props.token,
		};

		axios
			.put(url + '/profile/update-progress/', profileProgress)
			.then(res => this.props.updateProfile());

		axios
			.put(url + '/updateCount/add/' + language)
		}
		else{
			const profileProgress = JSON.parse(JSON.stringify(this.props.profile.progress));
			profileProgress.currentLanguage=language;

			const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";
			axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
			axios.defaults.xsrfCookieName = 'csrftoken';

			axios.defaults.headers = {
				'Content-Type': 'application/json',
				Authorization: 'Token ' + this.props.token,
			};

			axios
			.put(url + '/profile/update-progress/', profileProgress)
			.then(res => this.props.updateProfile());

		}

	};

	loadLanguages = () => {
    const db = firebase.firestore();
    const languagesHTML = [];
    const strLanguagesHTML = [];
    let languages = [];


      db.collection("Languages")
      .get()
      .then((data) => {


          data.forEach(doc => {
              languages.push({
                  id: doc.id,
                 ...doc.data()
              });
          });

          




		// const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";
    //
		// axios.get(url + '/languages').then(res => {

			for (let language of languages) {
				//language names
				//once you get the locations that aren't in the search
				//languagesHTML[i] = <div class="hidden">languagesHTML[i]</div>
				if(language.availWeb){
				languagesHTML.push(
					<Link key={language.id} name={language.name} to={!this.state.newUser ? '/courses/' + language.id: '/newUser/' + language.id + '/Survey'} className={styles.LanguageCard + ' ' + styles.ScaleAnimation}>
					<div onClick={() => this.addToMyLanguages(language.id)}>
							<div>
								<Image style={{display: 'inline'}}src={language.flag} width={70}rounded   />
							</div>
							<div >{language.id}</div>
							<div >{abbrNum(language.learnerCount, 2)} learners</div>
					</div>
					</Link>
				);
				// list used for search alg in findResults function
				strLanguagesHTML.push(language.id)
			}


			}

			this.setState({
				languagesHTML: languagesHTML,
				strLanguagesHTML: strLanguagesHTML,
				languagelist: languagesHTML,
			});

            })
            .catch(err => console.error(err));
		// });
	};

	handleInputChange = (e) => {
		this.setState({search: e.target.value})
		this.findResults(e.target.value);

	};

	findResults = (searchVal) => {
		const newLanguagesHTML = [];

		if (searchVal.length > 0) {
			for (let i = 0; i < this.state.languagesHTML.length; i++) {
				if (this.state.strLanguagesHTML[i].toLowerCase().indexOf(searchVal.toLowerCase()) >= 0) {
					newLanguagesHTML.push(this.state.languagesHTML[i]);
				}
			}
			if (newLanguagesHTML.length > 0) {
				this.setState({languagelist: newLanguagesHTML})
			} else {
				 this.setState({languagelist: <div style={{color:"#fff", fontSize:"1.1em"}}>No results!</div>})
			}

		} else {
			this.setState({languagelist: this.state.languagesHTML})
		}
  	};


	render() {
		let languagesHTML = this.state.languagesHTML;
		let strLanguagesHTML = this.state.strLanguagesHTML;

		let languagelist = this.state.languagelist;

		if (!languagelist) {
			languagelist = [];
			for (let i = 0; i < 8; i++) {
				languagelist.push(<div key={i} className={styles.LanguageCardPlaceHolder} />);
			}
		}

		return (
			<React.Fragment>
				<Helmet>
				<meta name="apple-itunes-app" content="app-id=1567445203, app-argument=myURL" /> 
				</Helmet>
				<Header url={this.props.match.url} />

				<div className={styles.body}>
					<div className=" mt-0 mb-16 mx-auto">
						<h4 style={{color:'white', marginTop:'10vh', marginLeft:'10vw'}}> I want to learn...</h4>
					</div>

					<input type="text"
						style={{backgroundColor:'transparent', fontFamily:'Muli', textAlign:'left'}}
						className={styles.SearchBar + ' ' + styles.ScaleAnimation}
						value={this.state.search}
						onChange={this.handleInputChange}
						placeholder={'Search languages...'}
					/>

					<div className={styles.ContainerMain}>
						<div className={styles.RightColumn}>
							{languagelist}
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
};

const mapStateToProps = state => {
	return {
		profile: state.profile,
		token: state.auth.token,
		newUser: state.newUser.newUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProfile: () => dispatch(profileActions.profileLoad()),
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Languages);
