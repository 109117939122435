import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {Image} from 'react-bootstrap'
import styles from './Experience.module.css'
import Beginner from '../beginner.png'
import Intermediate from '../intermediate.png'


const Experience = forwardRef((props, ref) => {

    const [answer, setAnswer] = useState("")

    useImperativeHandle(ref, () => ({
        getData() {
            
            return answer
        }

      }));

    return (
        <div class='FormBody'>
            <h2 style={{textAlign: 'center'}} class='QuestionHeader'> How experienced are you in {props.language}?</h2>
            <div className={styles.ContainerMain}>
                <button onClick={()=>setAnswer('beginner')} style={{font: 'inherit'}} className={styles.LanguageCard}>
                    <div>
                        <Image src={Beginner} width={70}rounded   />
                    </div>
                    <div >Learning for the first time!</div>
                </button>

                <button onClick={()=>setAnswer('intermediate')} style={{font: 'inherit'}}className={styles.LanguageCard}>
                    <div>
                        <Image src={Intermediate} width={70}rounded   />
                    </div>
                    <div >I already know some {props.language}!</div>
                </button>
            </div>
        </div>
    )
})

export default Experience
