import React, {Component} from 'react';
import {Row, Button, Image, Col} from 'react-bootstrap'
import {connect} from 'react-redux'
import * as profileActions from '../../../store/actions/profile';

import {Link} from 'react-router-dom'
import chatIcon from './img/chat.jpeg';
import  thinkIcon from './img/think.jpeg';
import  growIcon from './img/grow.jpeg';
import  africaIcon from './img/africa.jpeg';
import  writeIcon from './img/write.jpeg';
import 'bootstrap/dist/css/bootstrap.min.css';
import  styles from './Overview.module.css';



class Overview extends Component{
  state = {
    newUser: false,
  }

  componentDidMount() {
    if(window.location.pathname.includes("newUser")){
      this.setState({
        newUser:true,

    });

    }

    }

  // !this.props.newUser ? '/courses/' + this.props.language: '/newUser/' + 'courses/' +this.props.language

    render() {
        return (
            <div className={styles.animate}>
        <Row className={styles.heading1}><h1 className={styles.headingText}>Overview</h1></Row>
        <Row className={styles.heading2}><h2 > {this.props.language} Overview</h2></Row>
        <Row><div className={styles.normalText}>Improve listening, speaking, writing and reading skills in {this.props.language}</div></Row>

            <Row className={styles.overlined}><h3 className={styles.heading3}>Course breakdown</h3></Row>
            <Row className={styles.underlined}>
              <Col className={styles.split}><Image className={styles.splitImage} src={chatIcon}/>
              <div className={styles.midFontL}>1000+</div><span className={styles.midFontS}> words</span>
              </Col>
              <Col className={styles.split}><Image className={styles.splitImage}src={writeIcon}/>
              <div className={styles.midFontL}>800+</div><span className={styles.midFontS}> phrases</span>
              </Col>
            </Row>
            <Row className={styles.padding}>
              <h3 className={styles.heading4} >Course goals</h3>
            </Row>
            <Row >
              <Col xs={'auto'}><Image className={styles.image} src={thinkIcon}/></Col>
              <Col className={styles.text}><div className={styles.textAnimation}>Be able to maintain an intermediate conversation with a native speaker</div></Col>
            </Row>
            <Row>
              <Col xs={'auto'}><Image className={styles.image} src={africaIcon}/></Col>
              <Col className={styles.text}><div className={styles.textAnimation}>Learn about culture, traditions and history</div></Col>
            </Row>
            <Row>
              <Col xs={'auto'}><Image className={styles.image} src={growIcon}/></Col>
              <Col className={styles.text}><div className={styles.textAnimation}>Impress friends, family members and employers with your knowledge of the language</div></Col>
            </Row>

            <Link to={!this.state.newUser ? '/courses/' + this.props.language: '/newUser/learn/'+this.props.language+'/1/'} className={styles.LanguageCard}><div className="col text-center"><Button className={styles.Button}>Start Introduction</Button></div></Link>
          </div>


        )}
}

const mapStateToProps = state => {
	return {
		profile: state.profile,
		token: state.auth.token,
		newUser: state.newUser.newUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProfile: () => dispatch(profileActions.profileLoad()),
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Overview);
