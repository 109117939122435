import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col} from 'react-bootstrap'
import styles from './MultipleChoice.module.css';
import {correctAnswerSelected, incorrectAnswerSelected} from '../../.././store/actions/AnswerCheckActions'
import {connect }from 'react-redux'

function isPlaying(audio) { return !audio.paused; }

function stopPlaying(audio){
	if (isPlaying(audio)){
		audio.pause()
		audio.currentTime = 0
	}
	else{}
}

function isEllipsisActive(e) {
	return (e.offsetWidth < e.scrollWidth);
}


document.addEventListener('click', event => {
	if (event.target.matches('button')) {
	  event.target.focus()
	}
  })

  

const MultipleChoice = (props) => {

	//Styling state for correct answer
	const [button0styles, setbutton0styles] = useState("")
	const [button1styles, setbutton1styles] = useState("")
	const [button2styles, setbutton2styles] = useState("")

	//what the user's final choice on the screen was
	const [selected, setSelected] = useState(3)

	//styling state after the user presses "Check" on the screen
	const [selectedColor0, setSelectedColor0] = useState(styles.UnselectedColor)
	const [selectedColor1, setSelectedColor1] = useState(styles.UnselectedColor)
	const [selectedColor2, setSelectedColor2] = useState(styles.UnselectedColor)

	//bool for buttons' disabled state
	const[disabled, setDisabled] = useState(false)

	useEffect(() => {
		var buttons = document.getElementsByClassName(styles.Button);
  		for (var i = 0; i < buttons.length; i++) { 
		if (isEllipsisActive(buttons[i].getElementsByTagName('span')[0])){
			buttons[i].style.height="inherit"
		}
		
  	}
		
		//on reload set all buttons to active
		setDisabled(false)
		setSelectedColor0(styles.UnselectedColor)
		setSelectedColor1(styles.UnselectedColor)
		setSelectedColor2(styles.UnselectedColor)

		//after check is clicked,
		if (props.checkButtonClicked){
			
			//given correct answer choice is always po 0 in props,
			// select which of the randomized buttons on the screen has word 0.
			setSelected(props.order.indexOf(0))

			//depending on that result, set that button's color to green
			// and set the others to the default gray
			if (selected==0){
				setbutton0styles(styles.CorrectAnswer)
				setSelectedColor0('') //need to remove original orange selected styling.
				setbutton1styles('')
				setbutton2styles('')
			}

			if (selected==1){
				setbutton1styles(styles.CorrectAnswer)
				setSelectedColor1('')
				setbutton0styles('')
				setbutton2styles('')
			}

			if (selected==2){
				setbutton2styles(styles.CorrectAnswer)
				setSelectedColor2('')
				setbutton0styles('')
				setbutton1styles('')
			}

			if (selected==3){
			}
			setDisabled(true)			
		}
		else{
			setbutton0styles('')
			setbutton1styles('')
			setbutton2styles('')
			setDisabled(false)
		}
	})


	const order = props.order
	const audio0 = new Audio(props[order[0]].audio)
	const audio1 = new Audio(props[order[1]].audio)
	const audio2 = new Audio(props[order[2]].audio)


	let correctAnswerChoice=props[0].word;
		// const dispatch =useDispatch()

		const select=(selection)=>{
			if (selection === props[order[0]].word){
				stopPlaying(audio0)
				stopPlaying(audio1)
				stopPlaying(audio2)
				audio0.play()

			}
			if (selection === props[order[1]].word){
				stopPlaying(audio0)
				stopPlaying(audio1)
				stopPlaying(audio2)
				audio1.play()

			}

			if (selection === props[order[2]].word){
					stopPlaying(audio0)
					stopPlaying(audio1)
					stopPlaying(audio2)
					audio2.play()


			}
			if(selection===correctAnswerChoice){

				//dispatches
				props.correctAnswerSelected()

	}

			else{
				//dispatches
				props.incorrectAnswerSelected()
	
			}

		}
	return (

	<div style={{width:'100vw',height:'70vh', backgroundColor:'white', overflow: 'scroll'}}>
		<div className={styles.Main}>

		<div style={{    fontSize: "15vw",
		lineHeight: "30px",
				margin: "auto",
		textAlign: "center",
		width: '100%'}}>
		<div style={{    color:'black',fontSize: "35px",

				margin: "auto",
		textAlign: "center",
		width: '100%'
	}}> Which one of these is "{props[0].description}" ?</div>
				</div>

<Col style={{marginTop:'20vh'}}>
<Row style={{marginBottom:'2vh'}}>
<button disabled={disabled} onClick ={()=>select(props[order[0]].word,props[order[0]].audio)} style={{backgroundColor: 'none', marginBottom:'5vh', margin: 'auto',border:'none', font: 'inherit', borderRadius:'10px'}} className={button0styles + ' ' + selectedColor0 + ' ' + styles.Button }>
<span>{props[order[0]].word}</span>
</button>
</Row>

<Row style={{marginBottom:'2vh'}}>
<button disabled={disabled} onClick ={()=>select(props[order[1]].word,props[order[1]].audio)} style={{backgroundColor: 'none', marginBottom:'5vh', margin: 'auto',border:'none', font: 'inherit', borderRadius:'10px'}} className={button1styles + ' ' + selectedColor1+ ' ' + styles.Button}>
<span>{props[order[1]].word}</span>
</button>
</Row>
<Row style={{marginBottom:'2vh'}}>
<button disabled={disabled} onClick ={()=>select(props[order[2]].word,props[order[2]].audio)} style={{backgroundColor: 'none', marginBottom:'5vh', margin: 'auto',border:'none', font: 'inherit', borderRadius:'10px'}} className={button2styles + ' ' + selectedColor2 + ' ' + styles.Button}>
<span>{props[order[2]].word}</span>
</button>
</Row>
<Row style={{marginBottom:'2vh'}}>

</Row>
</Col>



		</div>




	</div>

);
}

const mapStateToProps = state => {
	return{
		checkButtonClicked: state.checkButtonHasBeenClicked.checkButtonClicked
	}
}
const mapDispatchToProps=(dispatch)=>{
	return {correctAnswerSelected:()=>dispatch(correctAnswerSelected()), incorrectAnswerSelected:()=>dispatch(incorrectAnswerSelected())}


}
export default connect(mapStateToProps,mapDispatchToProps)(React.memo(MultipleChoice));

MultipleChoice.propTypes = {
	word: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	next: PropTypes.func.isRequired,
};
