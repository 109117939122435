import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './JoinMailingList.module.css';
import Header from '../Login/Header/Header';
import firebase from "firebase/app";
import MetaTags from 'react-meta-tags'
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert'


import "firebase/auth";
import "firebase/firestore";

var db = firebase.database()

class JoinMailingList extends Component {
	state = {
		email: '',
		password: '',
		emailInputClasses: styles.Input,
	};

    subscribeClick = () => {
		var email = this.state.email
        var mail = db.ref('Emails')
		
        mail.orderByChild("email").equalTo(email)
        .once("value", snapshot =>{
            if (snapshot.exists()){alert("Already subscribed!")}
            else{

                var mailRef = db.ref('Emails').push()
                mailRef.set({
                    'email': email
                })
                alert("Subscribed!")
                var countRef = db.ref('NumCurrentSubs/currentCount')
		        countRef.transaction(function (current_value) {
			    return (current_value || 0) + 1;
		})
            }
        })	
	}

	inputChange = event => {
		const name = event.target.name;
		if (this.state[name + 'InputClasses'] === styles.Input + ' ' + styles.Error) {
			this.setState({
				[name]: event.target.value,
				[name + 'InputClasses']: styles.Input,
			});
		} else {
			this.setState({ [name]: event.target.value });
		}
	};

	render() {
		return (
			<React.Fragment>
				<MetaTags>
					 <title>Learn Languages with Sebmita</title>
					 <meta id="meta-description" name="description" content="Learn Languages with Sebmita" />
					 <meta id="og-title" property="og:title" content="Learn Languages with Sebmita" />
				 </MetaTags>
				<Header url={this.props.match.url} color={"white"} />
				<div className={styles.Content}>
					<div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
					color:"black",
					marginTop:"10vh",
        }}></div>
					<div className={styles.WhiteBox}>
						<div style={{paddingBottom: '40px'}}className={styles.LoginTitle}>Join our Mailing List!</div>
						<input
							name="email"
                            placeholder = "Email"
							onChange={this.inputChange}
							className={this.state.emailInputClasses}
						/>
						
                       
						<div onClick={this.subscribeClick} className={styles.LoginButton}>
							Subscribe
						</div>
                       
                        
					
<div style={{color:'black', fontSize:'15px'}}>
<span>
Not a Mandla user?
  <Link to="/join" >
<div className={styles.SignUpLink} style={{color:'orange'}}>
	Sign Up
</div>
</Link>
</span>
</div>
					</div>


				</div>

			</React.Fragment>
		);
	}
}




export default JoinMailingList;
