import React from 'react';
import {Row, Image, Container} from 'react-bootstrap'
import styles from './Introduction.module.css';
import soundIcon from './img/sound.png';
import ReactAudioPlayer from 'react-audio-player';
import {connect }from 'react-redux'
import {correctAnswerSelected, incorrectAnswerSelected} from '../../.././store/actions/AnswerCheckActions'
const Introduction = (props) => {
	props.correctAnswerSelected();
	return(

	<div style={{width:'100vw',height:'70vh', backgroundColor:'white', overflow: 'scroll'}}>
		<div >

		<div className={styles.challenge + styles.challengeTranslate}>
				<h1 className={styles.challengeHeader} styles={{marginTop:'70px'}}>
			{props[0].description} </h1>
				</div>

<Container>
<Row className="justify-content-center">
<div className={styles.WordCard} style={{border:'none'}}>

	<div className={props[0].context ? styles.WordCardInner : null}>
		<div className={props[0].context ? styles.WordCardFront : null}>
		<Image src={props[0].image} style={{  width:'300px', height:'200px', margin:'auto', marginTop:'0px'}}/>
		</div>
		<div className={props[0].context ? styles.WordCardBack : null}>
		<span className={styles.Context}><strong>{props[0].context}</strong> </span>
		</div>
	</div>

{/*<div className={styles.soundIcon}>

<img className={styles.soundIconImg}src={soundIcon}/></div>*/}
</div>
</Row>
<ReactAudioPlayer
  src={props[0].audio}
  autoPlay
style={{backgroundImg:{soundIcon}}}
className={styles.playAudio}
/>

<Row style={{paddingTop: '10px'}}className="justify-content-center">
<div className={styles.Word} style={{color:'#404040', fontSize:'20px'}}>{props[0].word}</div>
</Row>
<Row className="justify-content-center">

</Row>







</Container>
		</div>
	</div>

);
}
const mapDispatchToProps=(dispatch)=>{
	return {correctAnswerSelected:()=>dispatch(correctAnswerSelected()), incorrectAnswerSelected:()=>dispatch(incorrectAnswerSelected())}


}
export default connect(null,mapDispatchToProps)(React.memo(Introduction));
