import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Image, Col} from 'react-bootstrap'
import styles from './Image2Words.module.css';
import {correctAnswerSelected, incorrectAnswerSelected} from '../../.././store/actions/AnswerCheckActions'
import {connect }from 'react-redux'

function isPlaying(audio) { return !audio.paused; }

function stopPlaying(audio){
	if (isPlaying(audio)){
		audio.pause()
		audio.currentTime = 0
	}
	else{}
}

document.addEventListener('click', event => {
	if (event.target.matches('button')) {
	  event.target.focus()
	}
  })

const Image2Words = (props) => {
const [button0styles, setbutton0styles] = useState("")
const [button1styles, setbutton1styles] = useState("")
const [button2styles, setbutton2styles] = useState("")
const [selected, setSelected] = useState(3)


useEffect(() => {
	if (props.checkButtonClicked){
		setSelected(props.order.indexOf(0))
		if (selected==0){
			setbutton0styles(styles.CorrectAnswer)
			setbutton1styles('')
			setbutton2styles('')
		}

		if (selected==1){
			setbutton1styles(styles.CorrectAnswer)
			setbutton0styles('')
			setbutton2styles('')
		}

		if (selected==2){
			setbutton2styles(styles.CorrectAnswer)
			setbutton0styles('')
			setbutton1styles('')
		}

		if (selected==3){}
	}
	else{
		setbutton0styles('')
		setbutton1styles('')
		setbutton2styles('')
	}
})
const order = props.order
const audio0 = new Audio(props[order[0]].audio)
const audio1 = new Audio(props[order[1]].audio)
const audio2 = new Audio(props[order[2]].audio)


let correctAnswerChoice=props[0].word;
	// const dispatch =useDispatch()

	const select=(selection)=>{
		if (selection === props[order[0]].word){
			stopPlaying(audio0)
			stopPlaying(audio1)
			stopPlaying(audio2)
			audio0.play()

		}
		if (selection === props[order[1]].word){
			stopPlaying(audio0)
			stopPlaying(audio1)
			stopPlaying(audio2)
			audio1.play()

		}

		if (selection === props[order[2]].word){
				stopPlaying(audio0)
				stopPlaying(audio1)
				stopPlaying(audio2)
				audio2.play()

		}
		if(selection===correctAnswerChoice){

			//dispatches
			props.correctAnswerSelected()

}

		else{
			//dispatches
			props.incorrectAnswerSelected()

		}

		}
return (
	// overflow: scroll
	<div style={{width:'100vw',height:'70vh', backgroundColor:'white', overflow: 'scroll'}}>
		<div className={styles.Main}>

		<div style={{    fontSize: "15vw",
		lineHeight: "24px",
		margin: "auto",
textAlign: "center",
		width: '100%'}}>
		<div style={{    color:'black',fontSize: "30px",
				margin: "auto",
		textAlign: "center",
		width: '100%',marginBottom:'20px', lineHeight: '1.15'}}> Which image matches the word?</div>
				</div>
				<div style={{width:'30vw', color:'black', fontWeight:'bold',fontSize: "30px", 	margin: "auto",
			textAlign: "center"}}>
				{props[0].word}
				</div>
				<div style={{width:'100px', height:'5px',backgroundColor:'orange',	margin: "auto",
			textAlign: "center", marginBottom:'10px',marginBottom:'10px'}}>

				</div>

<Row style={{textAlign: 'center', margin: 'auto'}}>
<Col style={{marginBottom:'2vh'}}>
<Button onClick ={()=>select(props[order[0]].word,props[order[0]].audio)} style={{textAlign: "center",cursor:" pointer", fontWeight:'bold', color:'black',backgroundColor:'white',borderColor:'#e5e5e5',marginBottom:'5vh', width:'200px', height:'200px' , borderRadius:'30px',margin: 'auto',outline:'blue'}}className={button0styles}>
<Image src={props[order[0]].image} style={{ width:'170px', height:'140px', borderRadius:'30px'}}/>

</Button>
</Col>

<Col style={{marginBottom:'2vh'}}>
<Button  onClick ={()=>select(props[order[1]].word,props[order[1]].audio)} style={{textAlign: "center",cursor:" pointer", fontWeight:'bold', color:'black',backgroundColor:'white',borderColor:'#e5e5e5',marginBottom:'5vh', width:'200px', height:'200px' , borderRadius:'30px',margin: 'auto'}}className={button1styles}>
<Image src={props[order[1]].image} style={{ width:'170px', height:'140px', borderRadius:'30px'}} />


</Button>
</Col>

<Col style={{marginBottom:'2vh'}}>
<Button  onClick ={()=>select(props[order[2]].word,props[order[2]].audio)} style={{textAlign: "center",cursor:" pointer", fontWeight:'bold', color:'black',backgroundColor:'white',borderColor:'#e5e5e5',marginBottom:'5vh', width:'200px', height:'200px' , borderRadius:'30px',margin: 'auto'}}className={button2styles}>
<Image src={props[order[2]].image} style={{ width:'170px', height:'140px', borderRadius:'30px'}}/>

</Button>
</Col>

</Row>



		</div>




	</div>

);
}

const mapStateToProps = state => {
	return{
		checkButtonClicked: state.checkButtonHasBeenClicked.checkButtonClicked
	}
}
const mapDispatchToProps=(dispatch)=>{
	return {correctAnswerSelected:()=>dispatch(correctAnswerSelected()), incorrectAnswerSelected:()=>dispatch(incorrectAnswerSelected())}


}
export default connect(mapStateToProps,mapDispatchToProps)(React.memo(Image2Words));


 Image2Words.propTypes = {
	word: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	next: PropTypes.func.isRequired,
};
